const data = [
{
    name: "传奇游戏-破姐版",
    url: "http://122.228.116.145:88/",
    category_id: "6"
},
{
    name: "主公爱攻城-Gm",
    url: "https://demo2.9999le.com/aigongc/",
    category_id: "6",
    remok: "Gm工具：游戏内点左上角头像"
},
{
    name: "僵尸亮了",
    url: "https://file.gugudang.com/res/down/public/p_kapibala/web-mobile/jiangshiliangle/index.html",
    category_id: "6"
},
{
    name: "山海异兽进化",
    url: "https://joyjoyanimal.top/TheRumoredMaster/web-mobile/index.html",
    category_id: "6"
},
{
    name: "重启人生-Gm",
    url: "https://res5.eqmobi.com/monopoly/monopoly_20241223_1/index.html",
    category_id: "6",
    remok: "账号随意输入"
},
{
    name: "打工生活模拟器-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/168_1736149571010/index.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "采油小生",
    url: "https://homa.ali.landintheair.com/GasStation/NoAds/0.0.4/index.html",
    category_id: "6"
},
{
    name: "去旅行",
    url: "http://101.200.182.248/shoudongban/",
    category_id: "6"
},
{
    name: "最强毒液-GM",
    url: "http://debug.pumpkinheadgame.online/Run",
    category_id: "6"
},
{
    name: "玩成大师",
    url: "https://pub.selpay.cn/games/wcds/2/index.html",
    category_id: "6"
},
{
    name: "潜水员日记",
    url: "https://wsdw.dengdengkeji.com/qianhaibuyu/web0.0.2/sdk/index.html",
    category_id: "6"
},
{
    name: "重生八零当厂花",
    url: "https://s4.hulumao.top/cs/index.html",
    category_id: "6"
},
{
    name: "就我眼力好",
    url: "https://pengpengma.cdn.darknight.games/JWYLH/web/v0.4.6/web-mobile/index.html",
    category_id: "6"
},
{
    name: "超级电玩城",
    url: "https://assets.tinyfun.online/game/sale/playground/2.4.6/index.html",
    category_id: "6"
},
{
    name: "我进化贼强",
    url: "https://game2.sfplay.net/IncuBattle/v1.0.1/v1",
    category_id: "6"
},
{
    name: "魔性大冒险-Gm",
    url: "https://game2.sfplay.net/BraveTower/v1.3.1/v1",
    category_id: "6"
},
{
    name: "你来打我啊",
    url: "https://z10.tanyu.mobi/tanpaixyx/28023/web/1.0.1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "重拳格斗-Gm",
    url: "https://cooperation.pailedi.com/web2/dingbowen/zqgd/daren/v2/web-mobile/index.html",
    category_id: "6"
},
{
    name: "最强进化",
    url: "https://cdn.cdlionhead.com/minigame/web-mobile/index.html",
    category_id: "6"
},
{
    name: "女官逆袭记-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/246_1731463697786/index.html",
    category_id: "6"
},
{
    name: "村头大冒险",
    url: "http://ct-dy-cdn.topjoy.com/web/web-mobile/index.html",
    category_id: "6"
},
{
    name: "走上人生巅峰-Gm",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/H5/smyfk/GM_webgl_dbgs/index.html",
    category_id: "6"
},
{
    name: "秀到起飞",
    url: "https://www.hnxyy.fun/gameDemo/eqshow/",
    category_id: "6"
},
{
    name: "挪车高高手-手机版",
    url: "https://cdn.flysheeep.com/tanzhihuan/WebGL/WoZuiAiNuoChe/1231/1/index.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "茶叶蛋大冒险",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/152_1735627169518/index.html",
    category_id: "6"
},
{
    name: "梦幻美食记",
    url: "https://cdn.moblazer.com/HuoGuo/web/web0/index.html",
    category_id: "6"
},
{
    name: "反派我当定了-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/265_1735978601666/index.html",
    category_id: "6",
    remok: "Gm工具：游戏内点左上角头像-再点测试"
},
{
    name: "来发育呀",
    url: "https://game-cdn.njymkj.fun/web-new/Endless_Web/1.0.3/9eee49e6/index.html",
    category_id: "6"
},
{
    name: "做个大聪明",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/zhise/zgdcm/web/index.html",
    category_id: "6"
},
{
    name: "绝地幸存",
    url: "https://cdn.greatgood666.com/JueDiXingCun/WEB/1218/web-desktop/index.html",
    category_id: "6"
},
{
    name: "石油工厂大亨-Gm",
    url: "https://sdk.hwengame.com/IssuerPack/IdleOil/index.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "大佬来了-地狱高难度",
    url: "https://debug.pumpkinheadgame.online/SoulMage/",
    category_id: "6",
    remok: "免广告"
},
{
    name: "抓大鹅",
    url: "https://cdn-zhuadae.lanfeitech.com/dy/H5/prod/html/7.0/index.html",
    category_id: "6"
},
{
    name: "我智商贼溜",
    url: "http://whhay.youwanshe.cn/index.html",
    category_id: "6"
},
{
    name: "活下去老铁-Gm",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/1516ddbf7f00965da9a3014f52f7d043/index.html",
    category_id: "6"
},
{
    name: "帝王养成记-Gm",
    url: "https://mahjong.wqmss.online/H5/dwycj/index.html",
    category_id: "6"
},
{
    name: "英雄计划",
    url: "https://maoyumeng.club/YXJH/web-mobile/index.html",
    category_id: "6"
},
{
    name: "传奇横屏-内购版",
    url: "https://client.fgss.hz9x.cn/500/youxitest.php",
    category_id: "6"
},
{
    name: "卡皮巴拉",
    url: "https://file.gugudang.com/res/down/public/p_kapibala/web-mobile/jiangshiliangle/index.html",
    category_id: "6"
},
{
    name: "这活贼解压-Gm",
    url: "https://res.cjs001.com/h5/HeroWarrior_1115/index.html",
    category_id: "6"
},
{
    name: "我的急速人生",
    url: "https://static.game.jingyougz.com/fishisland_h5/car",
    category_id: "6"
},
{
    name: "怨种兄弟-Gm",
    url: "https://antplay888.com/LucklyHero/ry/3/web-mobile/index.html",
    category_id: "6"
},
{
    name: "神器打造大师-枪火小队-Gm",
    url: "https://szj.txhy1699.com/web_bbzombie_bt/241226_xr/index_white.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "汉字王者",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/tpdg/wzwsh5-0906/index.html",
    category_id: "6"
},
{
    name: "这关很难过",
    url: "https://res.kampfiregames.cn/runFish/web3/index.html",
    category_id: "6"
},
{
    name: "我的休闲时刻",
    url: "https://oss.renyouwangluo.cn/wdxxsk_dy/web/index.html",
    category_id: "6"
},
{
    name: "疯狂军团",
    url: "https://abc.szyqhd.com/web/cslj/fkjt/web-mobile/index.html",
    category_id: "6"
},
{
    name: "这个过山车有点快-免广-Gm",
    url: "https://sdk.hwengame.com/IssuerPack/IdleRoller/index.html",
    category_id: "6"
},
{
    name: "诡异不合理",
    url: "https://res5.haotgame.com/cu04/static/guiyibuheli/web-mobile/",
    category_id: "6"
},
{
    name: "末日小小队",
    url: "https://cdn-xyx-fire.raink.com.cn/9046/web-mobile/index.html",
    category_id: "6"
},
{
    name: "超进化大招版-Gm",
    url: "https://game2.sfplay.net/BagFighter/v1.0.1/v2/",
    category_id: "6"
},
{
    name: "地府传说",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/185_1732846562219/index.html",
    category_id: "6"
},
{
    name: "我养你啊",
    url: "https://cdn.hanyougame.cn/YangniGame/dgmnq/web-mobile/index.html",
    category_id: "6"
},
{
    name: "开局爆装备",
    url: "https://game2.sfplay.net/MachoSword/v1.0.2/v1/",
    category_id: "6"
},
{
    name: "一起来玩密室消除",
    url: "https://env-00jxhah9httu-static.normal.cloudstatic.cn/index.html",
    category_id: "6"
},
{
    name: "战车出击",
    url: "https://voodoo.ali.landintheair.com/CombatCruiser/Noad/1.0.0/index.html",
    category_id: "6"
},
{
    name: "欧皇奇兵",
    url: "http://cdn.cxgame.net/game/roll/index.html",
    category_id: "6"
},
{
    name: "炫斗奇兵-Gm",
    url: "https://game2.sfplay.net/KinghtFall/v1.0.8/v3/",
    category_id: "6"
},
{
    name: "厉害了我的哥-Gm",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/lhlwdg-dr/",
    category_id: "6"
},
{
    name: "药水骑士-Gm",
    url: "https://bhr.yudiangame.vip/ysqsGmWeb4/",
    category_id: "6",
    remok: "Gm工具：点左上角小人-再点Gm工具"
},
{
    name: "我不会割草-Gm",
    url: "https://szj.txhy1699.com/web_bbplants/241127/index.html",
    category_id: "6"
},
{
    name: "保卫猪猪-Gm",
    url: "https://oss.pppig.cc/piggy/dy/index.html",
    category_id: "6"
},
{
    name: "梦想改造师",
    url: "https://wsdw.dengdengkeji.com/toutai-qixiang/web0.0.5/web-mobile/index.html",
    category_id: "6",
    remok: "请勿点击游戏内的重新开始【会清空数据】"
},
{
    name: "就不得消停",
    url: "https://mini.ssflashgo.com/stonesh/cx/web/jbdxt/web1231_1900/web-mobile/index.html",
    category_id: "6"
},
{
    name: "拯救国王高难度版",
    url: "https://maoyumeng.club/YXJH/web-mobile/index.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "连了再连",
    url: "https://cdn.flysheeep.com/noadgame/linkBlock/20241223/web-mobile/index.html",
    category_id: "6"
},
{
    name: "带着系统玩修仙/爽文修仙指南-GM",
    url: "https://blsxx.glifegame.com/daozhang/web/1.4.13/index.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "乌冬的旅店-Gm",
    url: "https://twgame-hotel-1257666530.file.myqcloud.com/dy-web-mobile/index_9.4.2.3.html",
    category_id: "6"
},
{
    name: "开局有破碗-逆袭的乞丐-Gm",
    url: "https://dev-cdn.jshshsj.fit/qigai/web-mobile/index.html",
    category_id: "6",
    remok: "GM:游戏内点击上方金元宝"
},
{
    name: "胡记布庄-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/190_1733219876595/index.html",
    category_id: "6"
},
{
    name: "奶奶的菜谱",
    url: "https://res.heigame.com/webRes/GrandmaFoodV2.0/index.html",
    category_id: "6"
},
{
    name: "挖沙工厂模拟器-Gm",
    url: "https://sdk.hwengame.com/IssuerPack/IdleSandWebGL/index.html",
    category_id: "6"
},
{
    name: "大佬来了-简单低难度",
    url: "https://debug.pumpkinheadgame.online/SoulMage_Easy/",
    category_id: "6"
},
{
    name: "热血英雄团-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/270_1734344654451/index.html",
    category_id: "6"
},
{
    name: "星际战舰",
    url: "https://z10.tanyu.mobi/tanpaixyx/28023/web/1.0.5/web-mobile/index.html",
    category_id: "6"
},
{
    name: "篮球奥利给",
    url: "http://juzhen-1304272202.cos.ap-guangzhou.myqcloud.com/Basketball/bin/index.html",
    category_id: "6"
},
{
    name: "脑洞连连看",
    url: "https://ndllcdn.chujinggamefun.com/web/web_cmdyj/index.html",
    category_id: "6"
},
{
    name: "天天玩泡泡龙",
    url: "https://qzz2d.qzzres.com/bubble_bytenew_h5/web-mobile/index.html",
    category_id: "6"
},
{
    name: "异界入侵",
    url: "https://game2.sfplay.net/FlatBackpack/v1.0.1/v1/",
    category_id: "6"
},
{
    name: "幸福生活靠打拼",
    url: "https://daren.glifegame.com/piggy2_qwerlk/index.html",
    category_id: "6"
},
{
    name: "纸牌人生",
    url: "https://game-res.tos-cn-beijing.volces.com/remote-res/zhi-pai-ren-sheng/web/1.1.4/web-mobile/index.html",
    category_id: "6"
},
{
    name: "我玩梗特牛",
    url: "https://fkwg.xyx.mmhygame.com/wwgtn/web/v1_1_2/index.html",
    category_id: "6"
},
{
    name: "吸血鬼幸存者-Gm",
    url: "https://file.gugudang.com/res/down/public/p_tanqiubattle/web-mobile/lts2/web_all.html",
    category_id: "6"
},
{
    name: "帝王成长之路",
    url: "https://demo2.9999le.com/topking/",
    category_id: "6"
},
{
    name: "梦想创业记",
    url: "https://static.zuiqiangyingyu.net/wb_webview/oldDriver/dreamSetUp/web-mobile/index.html?123",
    category_id: "6"
},
{
    name: "我靠钓鱼买座岛",
    url: "https://static.game.jingyougz.com/fishisland_h5/web-mobile/",
    category_id: "6"
},
{
    name: "新拿下一座城-GM",
    url: "https://game2.sfplay.net/KinghtFall/v1.0.8/v3/",
    category_id: "6"
},
{
    name: "这关真上头",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/zhise/kpblllx/web/index.html",
    category_id: "6"
},
{
    name: "逆袭新人生",
    url: "https://gamecdn.xzwgameserver.host/lxxrs/lxxrs-web/1.0.8/index.html",
    category_id: "6"
},
{
    name: "丧尸来袭",
    url: "https://res.wqop2018.com/partner/shzl/tt_jslb/maoyumeng/index.html",
    category_id: "6"
},
{
    name: "魔女安琪拉",
    url: "https://preview.umgame.cn/alchemy/index.html",
    category_id: "6"
},
{
    name: "摆个路边摊",
    url: "https://game.niudu.com/drlj/bglbt/web-mobile/index.html",
    category_id: "6"
},
{
    name: "我必成佛-石猴逆袭记-Gm",
    url: "https://bhr.yudiangame.vip/406Web/",
    category_id: "6",
    remok: "GM开启方法：点设置-再点GM工具"
},
{
    name: "隐秘的档案",
    url: "https://file-hidfile.kidikidi.net/secretfiles/h5/promote/after/web-mobile/index.html",
    category_id: "6"
},
{
    name: "最强小兵-无双割草-仅限安卓下载",
    url: "https://ogm.lanzouq.com/i74F52gdi0gb",
    category_id: "6",
    remok: "末日屠龙29关"
},
{
    name: "解压我最牛",
    url: "https://cdn-najia.cygame666.cn/majiang/webgl/1732692238460/index.html",
    category_id: "6"
},
{
    name: "王牌外卖员",
    url: "https://minigame.test.joyelement.cn/h5/minigame/duck-for-house/H5",
    category_id: "6"
},
{
    name: "冲啊大猛男",
    url: "https://mixpet-1255466057.cos.ap-shanghai.myqcloud.com/web/dash_hero/web_0425/index.html",
    category_id: "6"
},
{
    name: "荒岛大作战-Gm",
    url: "https://cdn.rewanyouxi.com/games/link/web-mobile/index.html",
    category_id: "6"
},
{
    name: "玩梗脑洞王",
    url: "https://res.game.7to.cn/gameres/pgame2/wgndw/wghh1206/",
    category_id: "6"
},
{
    name: "重生当首富-Gm",
    url: "https://qcdn.bdoggame.com/cdn/release/csdsf/webVer/index.html",
    category_id: "6"
},
{
    name: "扫雷大挑战",
    url: "https://cdn.flysheeep.com/noadgame/linkBlockMine/20241205/web-mobile/index.html",
    category_id: "6"
},
{
    name: "生化地下城-Gm",
    url: "https://file.gugudang.com/res/down/public/p_tanqiubattle/web-mobile/lts/index.html",
    category_id: "6"
},
{
    name: "真香小吃摊-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_res/game237/daren/v1_2021314/webgl/index.html",
    category_id: "6"
},
{
    name: "消了个消啊-高性能",
    url: "https://res.wqop2018.com/partner/hzdy/lxcb/NoAD/202411271650/index.html",
    category_id: "6"
},
{
    name: "道道道人间道",
    url: "https://xiaoyouxi.tyoufun.com/puzzle26/dddrjd/index.html",
    category_id: "6"
},
{
    name: "挪车高高手-电脑版",
    url: "https://cdn.flysheeep.com/tanzhihuan/WebGL/WoZuiAiNuoChe/1231/1/indexPC.html",
    category_id: "6"
},
{
    name: "夺宝高手-Gm",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/6a6d81a456550a3346a5368232323e77/index.html",
    category_id: "6"
},
{
    name: "熊猫小镇-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/248_1728446800894/index.html",
    category_id: "6"
},
{
    name: "小小运输工",
    url: "https://game2.sfplay.net/minng3d/v3/",
    category_id: "6"
},
{
    name: "开局一只虫",
    url: "http://debug.pumpkinheadgame.online/HyperVenom/",
    category_id: "6"
},
{
    name: "我的模拟超市-躺赢玩家",
    url: "https://tianyugame-1259238728.file.myqcloud.com/wdmncs/index.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "武侠打工逆袭记-Gm",
    url: "https://game2.sfplay.net/NiXiJi/v1/",
    category_id: "6",
    remok: "GM开启方法：点设置-打开GM菜单"
},
{
    name: "数一亿粒米",
    url: "https://game-static.heicheng51.com/gm/sm/web-mobile/index.html",
    category_id: "6"
},
{
    name: "玩的有点水",
    url: "https://debug.pumpkinheadgame.online/Slime_ZS/",
    category_id: "6"
},
{
    name: "本宫重生了-卸甲归田-Gm",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/bgcsl_drwg/",
    category_id: "6"
},
{
    name: "开局一颗蛋",
    url: "https://game2.sfplay.net/ReleaseMNQ/dy/v1.1.0/v2/",
    category_id: "6"
},
{
    name: "小猪冲刺-Gm",
    url: "https://minigame2.txy6666.com/reServer/cat/tempTest2/webmobile/",
    category_id: "6"
},
{
    name: "挪车练习生",
    url: "https://aliyun.umgame.cn/minigame_preview/10591/1.0.0/web_self_tt/index.html",
    category_id: "6"
},
{
    name: "像棋不象棋-将子帅-Gm",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/xqbxqGame1.5.7_oss_xqbxq2/",
    category_id: "6",
    remok: "免广告"
},
{
    name: "来躺平呀",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/ry_ltpy/",
    category_id: "6"
},
{
    name: "天天来收纳",
    url: "https://www.znhy123.cn/ttlsn/web-mobile/index.html",
    category_id: "6"
},
{
    name: "梦想油田",
    url: "https://hscdn1.quanmin-game.com/test/202402070050/000/index.html",
    category_id: "6"
},
{
    name: "原料加工大师",
    url: "https://game2.sfplay.net/PocketFarm/v1.0.1/v3/",
    category_id: "6"
},
{
    name: "王者精英5V5",
    url: "https://wwjres.haowan823.cn/xyxh5_test/mb3d5v5_h5_test/ver2/?",
    category_id: "6"
},
{
    name: "超级开局",
    url: "https://debug.pumpkinheadgame.online/Slime/",
    category_id: "6"
},
{
    name: "新版-人生轨迹模拟器",
    url: "https://game2.sfplay.net/Bankruptcy/v1.2.0/v3",
    category_id: "6",
    remok: "免广告"
},
{
    name: "破屋取暖模拟",
    url: "https://wanbgame.com/game/webapp/poWuQuNuanMoNi/index.html",
    category_id: "6"
},
{
    name: "富婆买买买",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/peopleshop/web-mobile/index.html",
    category_id: "6"
},
{
    name: "水坝建造模拟器-Gm",
    url: "https://sdk.hwengame.com/IssuerPack/IdleDamBuild/index.html",
    category_id: "6"
},
{
    name: "实力打脸",
    url: "https://002game-1302634535.cos.ap-guangzhou.myqcloud.com/sclsj/web/index.html",
    category_id: "6"
},
{
    name: "重生练气十万年-Gm",
    url: "https://blsxx.glifegame.com/lianqi/web/1.5.14/index.html",
    category_id: "6"
},
{
    name: "小小生存队-Gm",
    url: "https://game2.sfplay.net/ClimbTower2D/v1.0.1/v3/",
    category_id: "6"
},
{
    name: "恐怖屠夫",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/laitangpingba/2/web-mobile/index.html",
    category_id: "6"
},
{
    name: "海底猎杀进化",
    url: "https://game2.sfplay.net/shby/",
    category_id: "6"
},
{
    name: "打工模拟器",
    url: "https://damnq-1259238728.file.myqcloud.com/test/index.html",
    category_id: "6"
},
{
    name: "天才神射手",
    url: "https://antplay888.com/LucklyHero/tkc/tcsss/1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "我的收纳摊",
    url: "https://z10.tanyu.mobi/tanpaixyx/16003/web/1.0.110/index.html",
    category_id: "6"
},
{
    name: "我不修仙只练剑",
    url: "https://game2.sfplay.net/PracticeSwords/v1.1.5/v4/",
    category_id: "6"
},
{
    name: "装修改造家",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/a4d99ef61fe1dae5513e7b8b36d11e1d/index.html",
    category_id: "6"
},
{
    name: "熊猫打工记-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_res/game248/daren/v1_2019435/webgl/index.html",
    category_id: "6",
    remok: "GM工具：游戏内点设置-打开GM工具"
},
{
    name: "僵尸来袭",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/255v2_1731663898618/index.html",
    category_id: "6"
},
{
    name: "超级人生-Gm",
    url: "https://res.wqop2018.com/partner/cdbhy/mndzz/Ancestor/index.html",
    category_id: "6"
},
{
    name: "最强狗头",
    url: "https://res.heigame.com/webRes/DogFightVer2.1.7/index.html",
    category_id: "6"
},
{
    name: "末日庇护所",
    url: "https://game2.sfplay.net/CatSentinel3D/v2",
    category_id: "6"
},
{
    name: "最强小兵-Gm",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/sandbox3/TPDG_bagfight/zqxb/web-mobile/index.html?_t=17181937204",
    category_id: "6",
    remok: "旧版本（没有末日屠龙模式）"
},
{
    name: "最牛剑客",
    url: "https://demo2.9999le.com/dbjnolimit/",
    category_id: "6"
},
{
    name: "植物进化杂交版-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/271_1732613205568/index.html",
    category_id: "6"
},
{
    name: "根本消不完",
    url: "https://liuzhaoling.com/lianlian/index.html",
    category_id: "6"
},
{
    name: "实在是高",
    url: "https://3d-game.chuanglinggame.com/SZSGGame/index.html",
    category_id: "6"
},
{
    name: "连了又连",
    url: "https://cos.ucpopo.com/llyl/client_web/?openid=_000f3of8E6EZEXTbvAZbym5UvWGR4k-Bwm_&sessid=wufyfxlkidl0yneudl1q6ud6wdeh",
    category_id: "6"
},
{
    name: "贪吃蛇世界",
    url: "https://demo-game-1305175708.cos.ap-chongqing.myqcloud.com/a21_bxdsj/index.html",
    category_id: "6"
},
{
    name: "天师请快点-Gm",
    url: "https://cdn.hongshunet.com/zyh/TSQKD/2.0.9.4/web-mobile/index.html",
    category_id: "6",
    remok: "游戏内左上角有GM"
},
{
    name: "最难游戏",
    url: "https://cdn.flysheeep.com/noadgame/linkBlock/20241129_1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "十字消样样",
    url: "http://game.migoup.com/game/szxyy/1/",
    category_id: "6"
},
{
    name: "一代梗王",
    url: "https://hm-ydgw.chuanglinggame.com/web/ydgw/index.html",
    category_id: "6"
},
{
    name: "干了个饭",
    url: "https://res.kampfiregames.cn/chef/web6/index.html",
    category_id: "6"
},
{
    name: "砖了个砖",
    url: "https://incubator-static.easygame2021.com/move-block-game/web-mobile/index.html",
    category_id: "6"
},
{
    name: "逆袭的一生-Gm",
    url: "https://oss.tobetopone.com/h5/nxdys/?ma_callerProcessIdentify=%3AminiappX&ma_callbackId=1&disable_auto_expose=1&enable_webview_select_search=1&need_out_animation=right&font_scale=1.0",
    category_id: "6"
},
{
    name: "人生重启-Gm",
    url: "https://mini.ssflashgo.com/stonesh/hh/fkw/web/rscq.html",
    category_id: "6"
},
{
    name: "老版-人生轨迹模拟器-",
    url: "https://game2.sfplay.net/lifepath/",
    category_id: "6",
    remok: "免广告"
},
{
    name: "召唤超神龙",
    url: "https://games.qtjoy.com/games/DragonNew/index.html",
    category_id: "6"
},
{
    name: "超级人生",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/H5/cjrs/web-mobile/index.html",
    category_id: "6"
},
{
    name: "难度飙升",
    url: "https://cdn.hiluluke.com/noad/nandu/index2.html",
    category_id: "6"
},
{
    name: "火力与小兵",
    url: "https://oss.intowin.cn/dy/mini/xiaobing/index.html",
    category_id: "6"
},
{
    name: "跟我一起守-Gm",
    url: "https://res.wqop2018.com/partner/cdyf/wxwdwwbwz/WXW/index.html",
    category_id: "6"
},
{
    name: "烧脑找茬王",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/hzwz2/snzcw/nh5/index.html",
    category_id: "6"
},
{
    name: "重生之路-重生少年-Gm",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/chessTest_1031/",
    category_id: "6"
},
{
    name: "人生模拟器",
    url: "https://tt-1256600868.cos.ap-chengdu.myqcloud.com/free/life/web-mobile/index.html",
    category_id: "6"
},
{
    name: "镶钻高手",
    url: "https://aaa.puffergames.com/GameServerData2/Unity/webgl_xzgs/webgl_xzgs_0805/",
    category_id: "6"
},
{
    name: "守卫大作战-不一样的塔防-Gm",
    url: "https://tos.qwpo2018.com/mp/game/web/inside/73c40a33e2151feac8cd5e33ccb5c644/index.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "寻宝游戏达人",
    url: "https://cxhdgame.cn/findPicGame/index.html",
    category_id: "6"
},
{
    name: "文字来找茬",
    url: "https://short.puzzles100.com/sl/Gbjiu8",
    category_id: "6"
},
{
    name: "萌宠收纳屋",
    url: "https://www.rewanyouxi.com/games/houses/web/index.html",
    category_id: "6"
},
{
    name: "麻将消一消",
    url: "http://47.108.200.73/WebGames/MJXYX/web-mobile/index.html",
    category_id: "6"
},
{
    name: "我要变最强",
    url: "https://cdngame.shuling.vip/BianJuDaShi/WoYaoBianZuiQiang/h5/web-mobile/index.html?1.0.8.1=",
    category_id: "6"
},
{
    name: "贪吃的苹果蛇",
    url: "https://packages.tobetopone.com/webGame/snake_gravity/",
    category_id: "6"
},
{
    name: "摆烂式修仙-双休有多好-Gm",
    url: "https://blsxx.glifegame.com/tangping/web/daren1.3.0/index.html",
    category_id: "6"
},
{
    name: "恐怖电梯",
    url: "https://outsourcingflash.com/game/",
    category_id: "6"
},
{
    name: "回到古代当帝王",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/HdgddhdGame",
    category_id: "6"
},
{
    name: "我不想重生啊/我的重生日记",
    url: "https://res.wqop2018.com/zhise/wbxcsa/15adea56bb59955f/index.html",
    category_id: "6"
},
{
    name: "贪吃蛇精英",
    url: "https://file.gugudang.com/res/down/public/p_tanchishe/web-mobile/lts/index.html",
    category_id: "6"
},
{
    name: "箭头消消消",
    url: "https://cdn.flysheeep.com/noadgame/TapAway/0821/TapAway0821_3/index.html",
    category_id: "6"
},
{
    name: "全民羽毛球-Gm",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/2ddd9ef89312a48e5a338a546bcb0349/index.html",
    category_id: "6"
},
{
    name: "有间烧烤店-手机版",
    url: "http://wxclient.gzqidong.cn/GunFactory/wencong_desk/index.html",
    category_id: "6"
},
{
    name: "原始小人族",
    url: "https://gamescdn.qingmanet.com/web/ysxrz_test/c572cf21/",
    category_id: "6"
},
{
    name: "战斗吧爷们",
    url: "http://shisuiwang.top/wydjs/web-mobile/index.html",
    category_id: "6"
},
{
    name: "点灯",
    url: "https://youxipic.88090.com/puzzle15/dyzbdd/index.html",
    category_id: "6"
},
{
    name: "人生逆袭路",
    url: "https://game2.sfplay.net/Phoenix/bytedance/v2/",
    category_id: "6"
},
{
    name: "放置探险团-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/270v2_1733402026427/index.html",
    category_id: "6"
},
{
    name: "洗车之王-Gm",
    url: "https://mini.ssflashgo.com//stonesh/v-lez/CarWash/web/external_link/index.html",
    category_id: "6"
},
{
    name: "高情商选择",
    url: "https://game-res.tos-cn-beijing.volces.com/remote-res/zhi-pai-ren-sheng/web/1.1.4/web-mobile/index.html",
    category_id: "6"
},
{
    name: "疯狂改造王",
    url: "http://cdn.game-meng.com/xiaoyouxi/xb/webgl/v1",
    category_id: "6"
},
{
    name: "燃烧吧大脑",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/embarrassingbrain_c/h5-tt/index.html",
    category_id: "6"
},
{
    name: "逍遥仙剑传",
    url: "https://littlegame.tomato123.cn/XYXXZ/index.html",
    category_id: "6"
},
{
    name: "进击吧英雄-冲吧小兵-Gm",
    url: "https://game-res.tos-cn-beijing.volces.com/web-games/jin-ji-ba-ying-xiong/1.0.6/web-mobile/index.html",
    category_id: "6"
},
{
    name: "向猛鬼开炮",
    url: "https://cooperation.pailedi.com/web2/wuff/tower-defence/demo/v31/web-mobile/index.html",
    category_id: "6"
},
{
    name: "文字修真传",
    url: "https://cdn-wenzixiuzhenzhuan.cygame666.cn/game041601/index.html",
    category_id: "6"
},
{
    name: "人生重开有多好-Gm",
    url: "https://daren.glifegame.com/chongsheng/daren/index.html",
    category_id: "6"
},
{
    name: "尸潮战车-腐山行-超级大比拼-GM",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/1516ddbf7f00965da9a3014f52f7d043/index.html",
    category_id: "6"
},
{
    name: "劈砖大师",
    url: "https://cooperation.pailedi.com/web2/dingbowen/pzds/daren/v7/web-mobile/index.html",
    category_id: "6"
},
{
    name: "食神火锅",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/700cfe333bb7dd5b8b1d18fcb31cf8d6/index.html",
    category_id: "6"
},
{
    name: "找茬大湿怀旧版",
    url: "https://cdngame.shuling.vip/BianJuDaShi/zhaoChaDaShi/H5/web-mobile/index.html?V1.0.6.1",
    category_id: "6"
},
{
    name: "我靠经营致富",
    url: "https://cdn.cxgame.net/game/xiaoshijie/index.html",
    category_id: "6"
},
{
    name: "爽剧之王-Gm",
    url: "https://daren.glifegame.com/sjzw/index.html",
    category_id: "6",
    remok: "GM工具：游戏内左上角头像，再点设置两个字"
},
{
    name: "和珅模拟器-Gm",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/hsmnq_dr/",
    category_id: "6",
    remok: "游戏内点上方铜钱+1万"
},
{
    name: "筑梦空间",
    url: "https://z10.tanyu.mobi/tanpaixyx/28020/Web/1.0.8/index.html",
    category_id: "6"
},
{
    name: "开局一条狗",
    url: "https://game2.sfplay.net/kjytg/",
    category_id: "6"
},
{
    name: "我的煎饼摊",
    url: "https://webgame-6gzwg8ti52e46012-1310209978.tcloudbaseapp.com/107/web-mobile/index.html",
    category_id: "6"
},
{
    name: "星球乱斗模拟",
    url: "https://qtweb.qtjoy.com/games/Gyroscope/index.html",
    category_id: "6"
},
{
    name: "疯狂战车",
    url: "https://file.gugudang.com/res/down/public/p_fkbmh/web/lts/index.html",
    category_id: "6"
},
{
    name: "我是显眼包",
    url: "https://hulumao.cdn.xiaojing.work/html5/jdtest/index.html",
    category_id: "6"
},
{
    name: "少年仙路",
    url: "http://yygame.mmopk.net/ImmortalPath/",
    category_id: "6"
},
{
    name: "遇见童话",
    url: "https://res.wqop2018.com/partner/zhld/yjth/web/index.html",
    category_id: "6"
},
{
    name: "疯狂建设王-Gm",
    url: "https://game2.sfplay.net/bathing3D/V2",
    category_id: "6",
    remok: "GM工具-点游戏内的左上角设置"
},
{
    name: "重生道长模拟器-Gm",
    url: "https://blsxx.glifegame.com/daozhang/web/1.4.13/index.html",
    category_id: "6"
},
{
    name: "我是冒险家-Gm",
    url: "https://a.unity3dcloud.cn/9180f58e-d308-4430-afe8-966bc3cf658c/488bf06deb50d55f53bef72eac8cc654",
    category_id: "6"
},
{
    name: "队友一败涂地",
    url: "https://game.haiwanwl.com/dyybtd_bytedance/?ma_callerProcessIdentify=%3AminiappX&ma_callbackId=3&disable_auto_expose=1&enable_webview_select_search=1&need_out_animation=right&font_scale=1.0",
    category_id: "6"
},
{
    name: "修仙来了",
    url: "https://littlegame.tomato123.cn/CQYGXE/index.html",
    category_id: "6"
},
{
    name: "爆梗找茬王",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/myDog/findFaultKing/web-mobile/index.html",
    category_id: "6"
},
{
    name: "标枪大作战",
    url: "https://qt.szyqhd.com:6001/huochairen/",
    category_id: "6"
},
{
    name: "躺平摸鱼",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/sandbox3/tangpingmy/web-mobile/index.html",
    category_id: "6"
},
{
    name: "穿越寻宝记",
    url: "http://47.106.185.161:8080/historytest/",
    category_id: "6"
},
{
    name: "这里有梗",
    url: "http://brainchildhood.ifireflygame.com/",
    category_id: "6"
},
{
    name: "花光十个亿",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/web_xjgt3/",
    category_id: "6"
},
{
    name: "连消大师",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/e8534b5d9bd336a0dd1611885fe6ec88/index.html",
    category_id: "6"
},
{
    name: "末日终防线",
    url: "http://littlegame.tomato123.cn/EndWorld/index.html",
    category_id: "6"
},
{
    name: "小小早餐店",
    url: "https://maoyumeng.club/XXZCD/web-mobile/index.html",
    category_id: "6"
},
{
    name: "开局一把刀",
    url: "https://security.feishu.cn/link/safety?target=https%3A%2F%2Fgame2.sfplay.net%2Fknife%2F&scene=ccm&logParams=%7B%22location%22%3A%22ccm_default%22%7D&lang=zh-CN",
    category_id: "6"
},
{
    name: "最强打工王-Gm",
    url: "https://z10.tanyu.mobi/tanpaixyx/28013/darenshow/index.html",
    category_id: "6"
},
{
    name: "怨种日常",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/yzrc_test/",
    category_id: "6"
},
{
    name: "秒射僵尸",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/plants/2/web-mobile/index.html",
    category_id: "6"
},
{
    name: "人类超级进化",
    url: "https://game2.sfplay.net/SuperEvolution/v1.1.1/",
    category_id: "6"
},
{
    name: "了不起的僵尸",
    url: "https://static-cdn.51haodong.com/oppo/ghostlamp-oppo/1/index.html",
    category_id: "6"
},
{
    name: "眼神真不错",
    url: "https://u.yigomob.com/sl/yszbc",
    category_id: "6"
},
{
    name: "抖个腿子",
    url: "https://littlegame.tomato123.cn/doutui/index.html",
    category_id: "6"
},
{
    name: "球球无限弹",
    url: "https://cdn.hiluluke.com/noad/qqwxt/index2.html",
    category_id: "6"
},
{
    name: "蜘蛛变异进化",
    url: "https://qtweb.qtjoy.com/games/ZhiZhuBianYiJinHua/index.html",
    category_id: "6"
},
{
    name: "双休有多好",
    url: "https://blsxx.glifegame.com/tangping/web/daren1.3.0/index.html",
    category_id: "6"
},
{
    name: "养猪致富新农村",
    url: "https://daren.glifegame.com/piggy2_qwerlk/index.html",
    category_id: "6"
},
{
    name: "街头美食逆袭-Gm",
    url: "https://daren.glifegame.com/jtmsnx/index.html",
    category_id: "6"
},
{
    name: "人生百味录",
    url: "https://z10.tanyu.mobi/tanpaixyx/28022/web/baiwei/index.html",
    category_id: "6"
},
{
    name: "消除大师傅",
    url: "https://cdn.flysheeep.com/fengjiajun/ScrewOutBase/SO1014/XCDSFWebGL3/index.html",
    category_id: "6"
},
{
    name: "超级找茬不合理",
    url: "https://res3.haotgame.com/cu04/static/chaojizhaochabuheli/web-mobile/",
    category_id: "6"
},
{
    name: "纸上攻防战",
    url: "https://game2.sfplay.net/DriftEmpire/dy/v3",
    category_id: "6"
},
{
    name: "消了个消啊-低性能",
    url: "https://res.wqop2018.com/partner/hzdy/lxcb/NoAD/202411271700/index.html",
    category_id: "6"
},
{
    name: "我强到爆表-Gm",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/49c74290b2bf5734301b4c91376c3243/index.html",
    category_id: "6"
},
{
    name: "废柴修仙-修真模拟器",
    url: "https://littlegame.tomato123.cn/XXMNQ/index.html",
    category_id: "6"
},
{
    name: "吕布重生模拟器",
    url: "https://mkw.youngwingtec.com/project_config/lbcs_web/",
    category_id: "6"
},
{
    name: "三国赘婿传",
    url: "https://mkw.youngwingtec.com/project_config/sgzx_web/",
    category_id: "6"
},
{
    name: "夸父追日",
    url: "https://ysxxtech.com/nodeserver/zhuiri/web-mobile/",
    category_id: "6"
},
{
    name: "高能梗王",
    url: "https://qianzong.darknight.games/gaonenggengwang/web/index.html",
    category_id: "6"
},
{
    name: "姜太公钓鱼",
    url: "https://ysxxtech.com/nodeserver/diaoyu/web-mobile/",
    category_id: "6"
},
{
    name: "文字消除",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/zhiyou/dmx/WenZiXiaoChu/418/web-mobile/index.html",
    category_id: "6"
},
{
    name: "美女包围了我",
    url: "https://wdztbj-1256464321.cos.ap-guangzhou.myqcloud.com/mnbwlw/index.html",
    category_id: "6"
},
{
    name: "最强螺丝王",
    url: "https://cdn.flysheeep.com/jiangnengren/ScrewOut/NoAd/20240731_1/NoAd/index.html",
    category_id: "6"
},
{
    name: "魂穿模拟器",
    url: "https://tt-1256600868.cos.ap-chengdu.myqcloud.com/free/life/web-mobile/index.html",
    category_id: "6"
},
{
    name: "重生之以牙还牙-Gm",
    url: "https://oss.tobetopone.com/h5/newCityReborn",
    category_id: "6",
    remok: "GM工具：游戏内点左上角头像，再点设置两个字"
},
{
    name: "枪战王者-枪战之王",
    url: "https://file.gugudang.com/res/down/public/p_xxqs/qzwz/web/web_all.html",
    category_id: "6"
},
{
    name: "打僵尸模拟器",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/255v2_1730468330678/index.html",
    category_id: "6"
},
{
    name: "勇者小队",
    url: "https://littlegames.tomato123.cn/8/yongzhexiaodui-preview/index.html",
    category_id: "6"
},
{
    name: "真上头鸭",
    url: "https://cdn.flysheeep.com/tanzhihuan/WebGL/ScrewBus3D/1128/1/index.html",
    category_id: "6"
},
{
    name: "一赚到底",
    url: "https://hscdn9.quanmin-game.com/burgerplease/tt_qxzcyj1/web4/index.html",
    category_id: "6"
},
{
    name: "帮助姐姐当女帝",
    url: "https://oss.zhejing.tech/webH5/zhenhuan-tiktok/index.html",
    category_id: "6"
},
{
    name: "火柴人战神",
    url: "https://macg-oss.oss-cn-hangzhou.aliyuncs.com/web-stickman122/index.html",
    category_id: "6"
},
{
    name: "躺平守卫战",
    url: "https://game2.sfplay.net/CatSentinelHP/TP/v1",
    category_id: "6"
},
{
    name: "麻了个麻将",
    url: "https://cdn.zhang-xian.com/cocos-mahjong-ten-match/wx-mini-game-mmtgd.qiniu.cut-grass/web-mobile-tt2/index.html",
    category_id: "6"
},
{
    name: "合成神龙2",
    url: "https://file.gugudang.com/res/down/public/p_fengkuangxiaoyutang/web/lts/index.html",
    category_id: "6"
},
{
    name: "重生人间再修仙-Gm",
    url: "https://blsxx.glifegame.com/tangping/web/daren1.3.0/index.html",
    category_id: "6"
},
{
    name: "收纳天才",
    url: "https://updatecdn.chutaonet.cn/collect/web_game/web-mobile/index.html",
    category_id: "6"
},
{
    name: "筑梦空间",
    url: "https://z10.tanyu.mobi/tanpaixyx/28020/Web/1.0.7/index.html",
    category_id: "6"
},
{
    name: "收纳达人",
    url: "https://webgame-6gzwg8ti52e46012-1310209978.tcloudbaseapp.com/109/1.0.0/web-mobile/index.html",
    category_id: "6"
},
{
    name: "打个僵尸-Gm",
    url: "https://file.gugudang.com/res/down/public/p_hegedacheche/web-mobile/lts/index.html",
    category_id: "6",
    remok: "点各种货币直接增加"
},
{
    name: "开局已是巅峰-建造小小世界",
    url: "https://wsdw.dengdengkeji.com/toutai-qixiang/web0.0.4/web-mobile/index.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "真的有点强",
    url: "https://h5.cjs001.com/game/screw_match/20241202_13/index.html",
    category_id: "6"
},
{
    name: "超神打工仔-Gm",
    url: "https://dev-cdn.jshshsj.fit/qigai/web-mobile/index.html",
    category_id: "6"
},
{
    name: "玩梗了解一下",
    url: "https://qcdn.bdoggame.com/ttgame/jokepartyWeb/webVer/index.html",
    category_id: "6"
},
{
    name: "夜市小当家",
    url: "http://game.i66wan.com/h5resource/goodspeed/ysxdj/h5/v0/web-mobile/index.html",
    category_id: "6"
},
{
    name: "消消大师2",
    url: "https://games.cdn.dianli100.com/mj/h5/1.0.9/index.html",
    category_id: "6"
},
{
    name: "挪车冲冲冲2",
    url: "https://cdn.flysheeep.com/yangyihui/NoAd/ColorMoveCar/090304/index.html",
    category_id: "6"
},
{
    name: "僵尸公路",
    url: "http://debug.pumpkinheadgame.online/CorpseRoad2",
    category_id: "6"
},
{
    name: "魔塔经典版",
    url: "https://eayew.com/resource/web/magic_tower/",
    category_id: "6"
},
{
    name: "回村的诱惑",
    url: "https://www.lekonghd.cn/hjbus/0.0.6/",
    category_id: "6"
},
{
    name: "陷阱塔防大师-Gm",
    url: "https://game.haiwanwl.com/trapMaster_bytedance_gm",
    category_id: "6"
},
{
    name: "火锅消不停",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/700cfe333bb7dd5b8b1d18fcb31cf8d6/index.html",
    category_id: "6"
},
{
    name: "启强逆袭记",
    url: "https://mkw.youngwingtec.com/project_config/qiangge_ttmobileweb/",
    category_id: "6"
},
{
    name: "合成枪王",
    url: "http://49.7.226.193:7777/index.html",
    category_id: "6"
},
{
    name: "枪组大师",
    url: "https://self-data-1256706884.cos.ap-chengdu.myqcloud.com/stickmantd/tdstickman_0417/index.html",
    category_id: "6"
},
{
    name: "老铁来一局",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/gecao/2/web-mobile/index.html",
    category_id: "6"
},
{
    name: "超能打桩-欢乐大闯关-我家里有一座矿-Gm",
    url: "https://www.higame.geek-meta.com/develop2/003_test/001_dgrm/h5_mobile/v1.01/index.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "华尔街之王",
    url: "https://tianyugame-1259238728.file.myqcloud.com/wdmncs/index.html",
    category_id: "6"
},
{
    name: "这次我很强",
    url: "https://res.heigame.com/webRes/MonsterHunterV1.1.0/index.html",
    category_id: "6"
},
{
    name: "建造英雄",
    url: "https://cdn.moblazer.com/BuildHeros/web-gm7/index.html",
    category_id: "6"
},
{
    name: "我要当食神",
    url: "https://daren.glifegame.com/nxmsj/index.html",
    category_id: "6"
},
{
    name: "我火力全开",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/jgncl3_web_12_4/",
    category_id: "6"
},
{
    name: "僵尸榨汁达人",
    url: "https://qtweb.qtjoy.com/games/ZombieJuicing/index.html",
    category_id: "6"
},
{
    name: "先赚一个亿",
    url: "https://cooperation.pailedi.com/web2/dingbowen/xzygy/web-mobile/index.html",
    category_id: "6"
},
{
    name: "割草大决战",
    url: "https://cdn.rewanyouxi.com/games/link/gecao/web-mobile/index.html",
    category_id: "6"
},
{
    name: "像素世界",
    url: "https://tt-1256600868.cos.ap-chengdu.myqcloud.com/free/pixelworld/index.html",
    category_id: "6"
},
{
    name: "千层酒店",
    url: "https://game.littleboy.net/games/QianCengJiuDian/index.html",
    category_id: "6"
},
{
    name: "万宁桌球",
    url: "https://game-res.tos-cn-beijing.volces.com/web-games/zzTest/zuoqiu/web-mobile/index.html",
    category_id: "6"
},
{
    name: "智力大挑战",
    url: "https://res.wqop2018.com/zhise/zghyl/hgdev310/index.html",
    category_id: "6"
},
{
    name: "消除麻将",
    url: "https://cdn.flysheeep.com/xuewenhong/Mahjong/wx704d4014f7568c2f/NoAdWeb/23/index.html",
    category_id: "6"
},
{
    name: "挖矿当老板-Gm",
    url: "https://game2.sfplay.net/minng3d/v3/",
    category_id: "6",
    remok: "GM工具-再点连续点击版本号"
},
{
    name: "闯关折断手",
    url: "https://www.jetlagisland.com/web/client/ht/web-mobile/index.html",
    category_id: "6"
},
{
    name: "保卫菜园-Gm",
    url: "https://oss.tobetopone.com/h5/zwtf_h5/",
    category_id: "6"
},
{
    name: "百万钓鱼佬-钓鱼逆袭人生",
    url: "https://cdngame.atomgameteam.com/minigame/game/fishGame/index.html?version=20243452353",
    category_id: "6"
},
{
    name: "密室消除",
    url: "https://www.alyminigame.com/game/ttmsxc/",
    category_id: "6"
},
{
    name: "老爹大排档",
    url: "https://qcdn.bdoggame.com/ttgame/lddpd/webVer/index.html",
    category_id: "6",
    remok: "口令218000"
},
{
    name: "我要爆装备-Gm",
    url: "https://cdn.moblazer.com/Exploere/web101/index.html",
    category_id: "6"
},
{
    name: "村口过庙会",
    url: "https://security.feishu.cn/link/safety?target=https%3A%2F%2Fres.sjzgxwl.com%2Fpingce%2FGuangYiGuangMiaoHui%2Findex.html&scene=ccm&logParams=%7B%22location%22%3A%22ccm_default%22%7D&lang=zh-CN",
    category_id: "6"
},
{
    name: "超级金铲子",
    url: "http://h5.sm.deniulor.com/mergeminers/daren/v1.0.9/",
    category_id: "6"
},
{
    name: "我玩梗贼6",
    url: "https://fkwg.xyx.mmhygame.com/wwgz6/web/index.html",
    category_id: "6"
},
{
    name: "鸭鸭收纳大师",
    url: "https://www.rewanyouxi.com/games/snds/",
    category_id: "6"
},
{
    name: "摇摆龙",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/zhsl/",
    category_id: "6"
},
{
    name: "救救狗狗",
    url: "https://static.zuiqiangyingyu.net/wb_webview/brain6/myDog/web/1.0.0/web-mobile/index.html",
    category_id: "6"
},
{
    name: "命运挑战-Gm",
    url: "https://res.wqop2018.com/partner/cdbhy/xjrs/Childbirth/index.html",
    category_id: "6"
},
{
    name: "开局一辆车-Gm",
    url: "https://qcdn.bdoggame.com/ttgame/kjylc/webVer/index.html",
    category_id: "6"
},
{
    name: "末日特工队",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/moritegong/1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "小法Q兵模拟器",
    url: "https://mkw.youngwingtec.com/project_config/xfqb/",
    category_id: "6"
},
{
    name: "逆袭之觉醒重生-Gm",
    url: "https://tm-web.sanzigame.cn/4.5.2/web-mobile/index.html",
    category_id: "6"
},
{
    name: "洗浴中心模拟器",
    url: "https://game2.sfplay.net/bathing3D/v1",
    category_id: "6"
},
{
    name: "守护家园岛",
    url: "https://gamecraft-web.oss-cn-shanghai.aliyuncs.com/shjyd/index.html",
    category_id: "6"
},
{
    name: "你家快没了",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/njkml_outNet_cs/",
    category_id: "6"
},
{
    name: "这关你不行-新版",
    url: "https://res.wqop2018.com/zhise/zgnbxls/gm777/025/index.html",
    category_id: "6"
},
{
    name: "我的乡村生活",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/web_xjgt3/",
    category_id: "6"
},
{
    name: "重生逆转人生",
    url: "https://game2.sfplay.net/Reigns/v1.1.0/v2/",
    category_id: "6"
},
{
    name: "兵不厌诈",
    url: "https://enlijoy.cn/game/bbyz/web/index.html",
    category_id: "6"
},
{
    name: "拔了个罐",
    url: "https://incubator-static.easygame2021.com/screw-puzzle/guest/567/webpackage/index.html",
    category_id: "6"
},
{
    name: "人类一路守卫-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/255v2_1731663898618/index.html",
    category_id: "6"
},
{
    name: "致富赢家-Gm",
    url: "https://cdn.xingmiles.cn/minigame/h5/pig/index.html",
    category_id: "6"
},
{
    name: "挑战之路-Gm",
    url: "https://res5.haotgame.com/cu04/static/tiaozhanzhilu_beibao/web-mobile/",
    category_id: "6"
},
{
    name: "智商入侵",
    url: "https://chengyyxdl.leiting.com/web-mobile/dingzi/index.html",
    category_id: "6"
},
{
    name: "一拳大师-Gm",
    url: "https://cooperation.pailedi.com/web2/dingbowen/yqds2/daren/web-mobile/index.html",
    category_id: "6"
},
{
    name: "文字大玩家",
    url: "https://play.qwplay.cn/zstext/",
    category_id: "6"
},
{
    name: "开个西餐厅",
    url: "https://game.csquyou.com/mhxcd/",
    category_id: "6"
},
{
    name: "乞讨赚出五套房",
    url: "http://h5iad.sp-card.cn/begging/index.html",
    category_id: "6"
},
{
    name: "文字找茬大师",
    url: "https://res3.haotgame.com/cu04/static/wenzizhaochadashi/web-mobile/?v=1.1.67",
    category_id: "6"
},
{
    name: "我在唐朝打工-Gm",
    url: "https://game2.sfplay.net/wzdtdg/",
    category_id: "6"
},
{
    name: "玩个梗吧",
    url: "https://wdztbj-1256464321.cos.ap-guangzhou.myqcloud.com/wggb-zhibo/index.html",
    category_id: "6"
},
{
    name: "沙场发育模拟器",
    url: "http://cdn.hanyougame.cn/scfy/webmobile3/index.html",
    category_id: "6"
},
{
    name: "愚公移大山",
    url: "https://game-static.heicheng51.com/gm/ygys/web-mobile/index.html?v=1.0.1",
    category_id: "6"
},
{
    name: "无尽传说",
    url: "http://h5.sm.deniulor.com/gartenofrainbowmonsters/h5/",
    category_id: "6"
},
{
    name: "赛车能手",
    url: "https://game.bytetimes.com/web-mobile/RaceAce2",
    category_id: "6"
},
{
    name: "开心电玩城-Gm",
    url: "https://assets.tinyfun.online/game/sale/super-playground/1.1.4/index.html",
    category_id: "6"
},
{
    name: "我的摆摊日子",
    url: "https://res.wqop2018.com/partner/xamy/wdbtrz/h5/index.html",
    category_id: "6"
},
{
    name: "我来跑步",
    url: "https://file-loseweight-dy.jiutaikeji.net/wx/loseweight_h5/v1/index.html",
    category_id: "6"
},
{
    name: "拯救大师傅",
    url: "https://z10.tanyu.mobi/tanpaixyx/28004/zjdsj_web/index.html",
    category_id: "6"
},
{
    name: "螺丝消消看",
    url: "https://cdn.flysheeep.com/jiangnengren/ScrewOut/NoAd/20240731_1/NoAd/index.html",
    category_id: "6"
},
{
    name: "我不是细狗",
    url: "https://xcdn.selpay.cn/games/wbsxg/na2/index.html",
    category_id: "6"
},
{
    name: "纸枪笔弹-Gm",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/234_1729757507773/index.html",
    category_id: "6"
},
{
    name: "做个拿手菜",
    url: "https://www.lekonghd.cn/zgnsc/0.6.3/",
    category_id: "6"
},
{
    name: "我要养女儿",
    url: "https://www.lekonghd.cn/wyynr/2.0.6/",
    category_id: "6"
},
{
    name: "搬砖模拟器",
    url: "https://lwfsj-1256464321.cos.ap-guangzhou.myqcloud.com/1.1.7/index.html",
    category_id: "6"
},
{
    name: "小帅逆袭记-Gm",
    url: "https://mini.ssflashgo.com/stonesh/v-lez/backToFeedYou/web/1110_1835/index.html",
    category_id: "6"
},
{
    name: "科目三",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/kemusanTTdrGame/",
    category_id: "6"
},
{
    name: "枪王大冒险",
    url: "https://file.gugudang.com/res/down/public/p_xxqs/web_qwdmx/index.html",
    category_id: "6"
},
{
    name: "修勾快递",
    url: "https://xiugou.76y.com/",
    category_id: "6"
},
{
    name: "西天取经-Gm",
    url: "https://game-static.heicheng51.com/gm/xitianqujing/web-mobile/index.html?_t=1705120735554",
    category_id: "6"
},
{
    name: "脑洞天花板",
    url: "https://cdngame.shuling.vip/BianJuDaShi/NaoDongTianHuaBan/h5/web-mobile/index.html?1.2.35.1",
    category_id: "6"
},
{
    name: "砖块弹球王者",
    url: "https://game-pkg.dooowin.com/open/g72/2023-12-20/8HJd5jOU/index.html",
    category_id: "6"
},
{
    name: "挪车冲冲冲-手机版",
    url: "https://cdn.flysheeep.com/noadgame/moveCarZ6/1203/index.html",
    category_id: "6"
},
{
    name: "挖矿我特牛",
    url: "https://qtweb.qtjoy.com/games/MiningCollect/index.html",
    category_id: "6"
},
{
    name: "重生之都市仙帝",
    url: "https://chengdu.redraingame.com/html_xiao_fan/index.html",
    category_id: "6"
},
{
    name: "关不住我吧-乌龟对对碰",
    url: "https://res.wqop2018.com/partner/hzdy/gbzwb/H5_gbzwb/8_26/web-mobile/index.html",
    category_id: "6"
},
{
    name: "头脑大聪明-玩梗小天才",
    url: "http://www.szlinglewangluo.com/web-mobile/",
    category_id: "6"
},
{
    name: "我的智商不够用",
    url: "https://u.yigomob.com/sl/wdzsbgy",
    category_id: "6"
},
{
    name: "拯救我的漂亮女友",
    url: "https://lvgame.android.riofun.cn/emperorhtml/zjny/",
    category_id: "6"
},
{
    name: "打工没前途-Gm",
    url: "https://cdn.hiluluke.com/noad/dagong/index2.html?auto=true",
    category_id: "6"
},
{
    name: "收纳人生",
    url: "https://www.lekonghd.cn/myhouse/3.1.14/",
    category_id: "6"
},
{
    name: "脑洞大开3D",
    url: "https://file.gugudang.com/res/down/public/p_roomsort/web-mobile/lts2/index.html",
    category_id: "6"
},
{
    name: "猪了个猪",
    url: "https://cdn.flysheeep.com/noadgame/PigRun/030601/index.html",
    category_id: "6"
},
{
    name: "守个球啊",
    url: "https://littlegame.tomato123.cn/TheBlack/index.html",
    category_id: "6"
},
{
    name: "重生亿万富翁",
    url: "https://cdn.zqygame.com/testGame/cssn_outtest_1.0.0_noad/index.html",
    category_id: "6"
},
{
    name: "猎梦者小队",
    url: "https://game2.sfplay.net/CatSentinelHP/TP/1.2.5/v2/",
    category_id: "6"
},
{
    name: "聪明开局",
    url: "https://cdn.flysheeep.com/noadgame/carScrew2d/20241126/web-mobile/index.html",
    category_id: "6"
},
{
    name: "彩虹猪猪岛-Gm",
    url: "https://test-cdn.cmaom.com/minigame/kz/web-mobile/index.html",
    category_id: "6"
},
{
    name: "大哥饶命-Gm",
    url: "https://www.higame.geek-meta.com/develop2/003_test/001_dgrm/h5_mobile/v1.01/index.html",
    category_id: "6"
},
{
    name: "嘎嘎解压",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/tortoise/h5/index.html",
    category_id: "6"
},
{
    name: "这才是消除",
    url: "https://res.wqop2018.com/partner/hzdy/lxcb/NoAD/202409131848/index.html ",
    category_id: "6"
},
{
    name: "要超时啦",
    url: "https://res.wqop2018.com/partner/lytc/ycsl/webgl/1.0.2/index.html",
    category_id: "6"
},
{
    name: "格斗战机",
    url: "https://res.wqop2018.com/partner/xmmym/fz/web/GDZJ/web-mobile1/index.html",
    category_id: "6"
},
{
    name: "冲了个冲啊",
    url: "https://yygame.mmopk.net/H5AnimalEliminationNew/202411251648/index.html",
    category_id: "6"
},
{
    name: "我的寿司酒吧+Gm",
    url: "http://h5.sm.deniulor.com/sushi/daren/",
    category_id: "6"
},
{
    name: "好玩个锤子",
    url: "https://oss.tobetopone.com/h5/playHammer/",
    category_id: "6"
},
{
    name: "小小挖矿记-Gm",
    url: "https://game2.sfplay.net/minng3d/v1/",
    category_id: "6"
},
{
    name: "我欲成仙",
    url: "https://game.hongshunet.com/wenyun/GGCS/TT/web-mobile/index.html",
    category_id: "6"
},
{
    name: "菜鸟消消消",
    url: "https://www.lekonghd.cn/cainiao/0.5.6/",
    category_id: "6"
},
{
    name: "炫彩节奏2",
    url: "https://musicgame.xplaymobile.com/Games/MusicColorBall2/web-mobile/index.html",
    category_id: "6"
},
{
    name: "怪兽星球",
    url: "https://game.haiwanwl.com/gsxq_bytedance1",
    category_id: "6"
},
{
    name: "武林大厨",
    url: "https://file.gugudang.com/res/down/public/p_myhotel/web-mobile/lts/index.html",
    category_id: "6"
},
{
    name: "超能整活",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/zhise/cnzh/web/index.html",
    category_id: "6"
},
{
    name: "开局托儿所",
    url: "https://incubator-static.easygame2021.com/eat-grass-game/web-mobile/index.html",
    category_id: "6"
},
{
    name: "逆袭奇遇记-Gm",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/49c74290b2bf5734301b4c91376c3243/index.html",
    category_id: "6"
},
{
    name: "谁是爆梗王",
    url: "https://mini.ssflashgo.com/wt62/ssbgw/web/2023121502/web-mobile/index.html",
    category_id: "6"
},
{
    name: "像素勇者世界-Gm",
    url: "https://cpgame-1259238728.file.myqcloud.com/pixelWorld/test/index.html",
    category_id: "6"
},
{
    name: "茶叶蛋大作战",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/192_1733214367935/index.html",
    category_id: "6"
},
{
    name: "开局一颗球",
    url: "https://littlegame.tomato123.cn/kjykq/index.html",
    category_id: "6"
},
{
    name: "躲猫猫秘密行动",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/sandbox3/duomaomao/v1/web-mobile/index.html?_t=1704270306648",
    category_id: "6"
},
{
    name: "挪车冲冲冲-电脑版",
    url: "https://cdn.flysheeep.com/noadgame/moveCarZ6/1203/indexPc.html",
    category_id: "6"
},
{
    name: "进阶2048",
    url: "https://mini.ssflashgo.com/stonesh/v-lez/smallTadpole2048/web_0824_1632/index.html",
    category_id: "6"
},
{
    name: "最强防御战",
    url: "https://res.cjs001.com/h5/DuskWarZ/web/index.html",
    category_id: "6"
},
{
    name: "这关你不行",
    url: "http://1.117.9.183/_miniGame/daluosi2/web-mobile/",
    category_id: "6"
},
{
    name: "时代绿皮车",
    url: "https://webgame-6gzwg8ti52e46012-1310209978.tcloudbaseapp.com/119/ttzhise/web-mobile/index.html",
    category_id: "6"
},
{
    name: "我靠钓鱼娶女神",
    url: "https://game2.sfplay.net/HookFish/v1.0.0/v1",
    category_id: "6"
},
{
    name: "爬塔小飞棍",
    url: "https://candymatch-update.fjbgwl.com/webmobile11/index.html",
    category_id: "6"
},
{
    name: "子弹飞车联盟",
    url: "https://gz-1305296755.cos.ap-guangzhou.myqcloud.com/%E4%BD%93%E9%AA%8C%E9%93%BE%E6%8E%A5/%E5%AD%90%E5%BC%B9%E9%A3%9E%E8%BD%A6%E8%81%94%E7%9B%9F/web/index.html",
    category_id: "6"
},
{
    name: "这就是冒险",
    url: "https://oss.tobetopone.com/h5/zjsmx/",
    category_id: "6"
},
{
    name: "小怪兽迷你枪战",
    url: "https://ztyxyx.tanyu.mobi/cxwl/Gun666_masterman/web-mobile/index.html",
    category_id: "6"
},
{
    name: "发育者联盟",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/H5/fyzlm/web-mobile3/index.html",
    category_id: "6"
},
{
    name: "不是一班的牛",
    url: "https://yxcy-game-bsybdn.chuanglinggame.com/TabwD/web-mobile/index.html",
    category_id: "6"
},
{
    name: "龙的土豪人生",
    url: "http://ml.beihaixunmai.com/ml/webgl_1.0.9/",
    category_id: "6"
},
{
    name: "找茬达人找不同",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/finddifferent2/h5-tt/index.html",
    category_id: "6"
},
{
    name: "猜不透我吧",
    url: "https://candymatch-update.fjbgwl.com/noAd/store1/index.html",
    category_id: "6"
},
{
    name: "玩就完事了",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/snd/h5wjwsl/index.html",
    category_id: "6"
},
{
    name: "合成大西瓜3D",
    url: "https://cdn.gzgame99.cn/hcdxg/html/index.html",
    category_id: "6"
},
{
    name: "这不是汉字闯关",
    url: "http://tos.wqop2018.com/mp/game/web/gm/7fefb8165695e3d436a9cb1cc59a5c21/",
    category_id: "6"
},
{
    name: "魔兽进化论2",
    url: "https://cdn17.tomato123.cn/msjhlOld2/web/104/web-mobile/index.html",
    category_id: "6"
},
{
    name: "投资大师-我靠经营致富",
    url: "https://tianyugame-1259238728.file.myqcloud.com/wdmncs/index.html",
    category_id: "6"
},
{
    name: "魔王再临",
    url: "https://mkw.youngwingtec.com/daoshi2_mwzl/",
    category_id: "6"
},
{
    name: "烧脑也疯狂-Gm",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/bgcsl_drwg/",
    category_id: "6"
},
{
    name: "放置我贼溜-Gm",
    url: "http://debug.pumpkinheadgame.online/Stone/",
    category_id: "6"
},
{
    name: "整活大作战-Gm",
    url: "https://imqiu.darknight.games/web2/index.html",
    category_id: "6"
},
{
    name: "无双割草-Gm",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/sandbox3/TPDG_bagfight_code/fkxb/web-mobile/index.html?_t=1723824836851",
    category_id: "6",
    remok: "旧版本（没有末日屠龙模式）"
},
{
    name: "暴走的人生-Gm",
    url: "https://qcdn.bdoggame.com/ttgame/kjygy/webVer/index.html",
    category_id: "6"
},
{
    name: "重生逆转人生",
    url: "https://game2.sfplay.net/Bankruptcy/v1.2.2/v1/",
    category_id: "6"
},
{
    name: "打个螺丝儿",
    url: "https://cdn.flysheeep.com/noadgame/screwJam/20240718/web-mobile/index.html",
    category_id: "6"
},
{
    name: "主公来消它-Gm",
    url: "https://www.lekonghd.cn/zzz/33S50z72M1125420A",
    category_id: "6",
    remok: "游戏内点GM"
},
{
    name: "爱上消消",
    url: "https://cdngame.shuling.vip/BianJuDaShi/AiShangXiaoXiao/H5/web-mobile/index.html?V1.0.1",
    category_id: "6"
},
{
    name: "果趣连连消",
    url: "https://liuzhaoling.com/lianliankan_zhisesaolei_231/index.html",
    category_id: "6"
},
{
    name: "仙途问剑",
    url: "http://yygame.mmopk.net/ImmortalPath/",
    category_id: "6"
},
{
    name: "疯狂爆梗王",
    url: "https://maoyumeng.cdn.xiaojing.work/chdl/fkbgw/web/web-mobile/index.html",
    category_id: "6"
},
{
    name: "筑梦之旅",
    url: "https://z10.tanyu.mobi/tanpaixyx/28008/web/1.0.4/index.html",
    category_id: "6"
},
{
    name: "文字大玩家",
    url: "https://play.qwplay.cn/zstext/",
    category_id: "6"
},
{
    name: "再创辉煌",
    url: "https://cdn.doflame.com.cn/NewFutureYou/web-mobile/",
    category_id: "6"
},
{
    name: "生化围城",
    url: "http://h5.sm.deniulor.com/basedefence/daren/",
    category_id: "6"
},
{
    name: "挖掘机逆袭记",
    url: "https://test-cdn.ququwangluo.com/excavator/dr/index.html",
    category_id: "6"
},
{
    name: "收纳物语",
    url: "https://cdn.hanyougame.cn/snwy_web_1.0.1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "文字入侵",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/lyf_test_wz_0711/",
    category_id: "6"
},
{
    name: "艾泽拉斯小队",
    url: "https://game2.sfplay.net/CatSentinelHp/v1",
    category_id: "6"
},
{
    name: "人生逆袭记-Gm",
    url: "https://res.game.7to.cn/gameres/pgame2/dgnxj/lkz92fxv/",
    category_id: "6"
},
{
    name: "热梗大爆炸",
    url: "https://gametos.keepmobi.com/web-games/re-geng-da-bao-zha/1.0.30/web-mobile/index.html",
    category_id: "6"
},
{
    name: "遇见你的花",
    url: "https://webgame-6gzwg8ti52e46012-1310209978.tcloudbaseapp.com/118/1.0.0/web-mobile/index.html",
    category_id: "6"
},
{
    name: "地下城与魔兽",
    url: "https://cdn17.tomato123.cn/web-mobile2/index.html",
    category_id: "6"
},
{
    name: "夜市餐厅",
    url: "https://game2.sfplay.net/nightMarket/v1",
    category_id: "6"
},
{
    name: "躺平鱼王",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/sandbox3/tangpingmy/zbly/web-mobile/index.html?t=1666688485408",
    category_id: "6"
},
{
    name: "水果消一消",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/sgxxl_dr/",
    category_id: "6"
},
{
    name: "文字挑战/成语挑战大玩家",
    url: "https://www.lekonghd.cn/cyxxx/0.3.1/",
    category_id: "6"
},
{
    name: "少年逆袭记",
    url: "https://www.lekonghd.cn/snnxj1/1.3.8/",
    category_id: "6"
},
{
    name: "新跳一跳",
    url: "https://herofight.cn/preview/JumpAndJumpMobile/",
    category_id: "6"
},
{
    name: "潜水捕鱼",
    url: "https://cpgame-1259238728.file.myqcloud.com/qsby/no_ad/index.html",
    category_id: "6"
},
{
    name: "停车我最牛",
    url: "https://test.car.darknight.games/",
    category_id: "6"
},
{
    name: "天天找找不同",
    url: "https://res.game.7to.cn/gameres/pgame2/ttzbt/ttzbtlk12/",
    category_id: "6"
},
{
    name: "打到100关",
    url: "https://www.joyhearttech.com/blood/Coins/Web/web-mobile4/",
    category_id: "6"
},
{
    name: "史上最难挑战",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/puzzleplayer/h5-tt/index.html",
    category_id: "6"
},
{
    name: "狗狗创业记-Gm",
    url: "https://cdn.antplay888.com/gouGouChuangYeJi/ouihgui4/web-mobile/index.html",
    category_id: "6"
},
{
    name: "三国火柴人战神",
    url: "https://macg-oss.oss-cn-hangzhou.aliyuncs.com/web-kingdom3/index.html",
    category_id: "6"
},
{
    name: "俄罗斯方块新版",
    url: "https://z10.tanyu.mobi/tanpaixyx/18003/web/1.0.6/web-mobile/index.html",
    category_id: "6"
},
{
    name: "停车场大亨-Gm",
    url: "https://assets.tinyfun.online/game/sale/car-lot/latest/index.html",
    category_id: "6"
},
{
    name: "线条趣味闯关",
    url: "https://www.lekonghd.cn/xtqwcg/0.0.3/",
    category_id: "6"
},
{
    name: "最强防御战",
    url: "https://res.cjs001.com/h5/DuskWarZ/web/index.html",
    category_id: "6"
},
{
    name: "隐形背叛者",
    url: "https://xiaorenshangche-1300810619.cos.ap-guangzhou.myqcloud.com/ragecontrol/webgl/4.4/index.html",
    category_id: "6"
},
{
    name: "一代沙雕",
    url: "https://chuangling.cdn.xiaojing.work/byte/h5/web-mobile/index.html",
    category_id: "6"
},
{
    name: "第亿次收纳",
    url: "https://maoyumeng.club/dycsn/web/web-mobile/index.html",
    category_id: "6"
},
{
    name: "文字真相",
    url: "https://cdnsource-1.oss-cn-hangzhou.aliyuncs.com/tanpaixyx/28009/tt_wzzx/web-mobile/index.html",
    category_id: "6"
},
{
    name: "画纸弹兵",
    url: "https://game2.sfplay.net/Rolling_Legions/v1.0.0/v5/",
    category_id: "6"
},
{
    name: "你聪明吗-Gm",
    url: "http://h5.sm.deniulor.com/epicwarrior/h5/",
    category_id: "6"
},
{
    name: "我坐哪里",
    url: "https://qcdn.bdoggame.com/ttgame/findSeat/web/index.html",
    category_id: "6"
},
{
    name: "蘑菇冲突",
    url: "https://glyx2.17tcw.com/H5/Test/mgct/12/web-mobile/index.html",
    category_id: "6"
},
{
    name: "打个螺丝钉",
    url: "https://cdn.flysheeep.com/noadgame/ImageScrew/20240412_daren/web-mobile/index.html",
    category_id: "6"
},
{
    name: "全民大卖场",
    url: "https://hscdn1.quanmin-game.com/game/ShoppingMall/WebGL/202404011110/index.html",
    category_id: "6"
},
{
    name: "脑洞竞技场",
    url: "https://res.game.7to.cn/gameres/pgame2/hzqyh/zbwg3031/",
    category_id: "6"
},
{
    name: "卡车模拟驾驶",
    url: "https://game2.sfplay.net/thecar",
    category_id: "6"
},
{
    name: "黑子别想跑",
    url: "https://littlegame.tomato123.cn/TheBlack/index.html",
    category_id: "6"
},
{
    name: "吃我一砖-绝地僵尸战争-Gm",
    url: "https://file.gugudang.com/res/down/public/p_lx_cwyz/web-mobile/lts/index.html",
    category_id: "6"
},
{
    name: "我要回老家",
    url: "https://cdngame.shuling.vip/BianJuDaShi/WoYaoHuiLaoJia/H5/web-mobile/index.html?V1.0.1=",
    category_id: "6"
},
{
    name: "割草魔塔地下城",
    url: "https://cdn.198434.com/cli/H5/g0420/",
    category_id: "6"
},
{
    name: "落魄少爷逆袭记",
    url: "https://z10.tanyu.mobi/tanpaixyx/210007/shaoyett/darenshow/index.html",
    category_id: "6"
},
{
    name: "魔性秀秀秀",
    url: "https://fkwg.xyx.mmhygame.com/mxxxx/zbwg_241210CYLa/index.html",
    category_id: "6"
},
{
    name: "电玩俱乐部",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/matchStickMan/cjdwc/h5_daren/web-mobile/index.html",
    category_id: "6"
},
{
    name: "疯狂闯100关",
    url: "https://file.gugudang.com/res/down/public/p_xxqs/web_qwdmx/index.html",
    category_id: "6"
},
{
    name: "这就是逆袭",
    url: "https://h5game.godricc.com/out/plotjam/v2/index.html",
    category_id: "6"
},
{
    name: "一枪打爆海陆空",
    url: "http://ml.beihaixunmai.com/ml/webgl_hlk_1.0.4/",
    category_id: "6"
},
{
    name: "小小矿商",
    url: "https://minigame2.txy6666.com/reServer/cat/pertroleum/web-mobile/",
    category_id: "6"
},
{
    name: "逃离五指山",
    url: "http://shisuiwang.top/tlwzs/web-mobile/",
    category_id: "6"
},
{
    name: "男人要逆袭",
    url: "https://res.sjzgxwl.com/CHZ/GameWeb/GS_nrynx_202406261827/index.html",
    category_id: "6"
},
{
    name: "领地守卫战",
    url: "https://res.sjzgxwl.com/yk/bald/1.22/index.html",
    category_id: "6"
},
{
    name: "这个你会吗",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/cp/tt_wzwz/001_toilet/h5daren-1.0.0/index.html",
    category_id: "6"
},
{
    name: "我要做网红-Gm",
    url: "https://z10.tanyu.mobi/tanpaixyx/28022/web/1.0.1/index.html",
    category_id: "6",
    remok: "GM工具-在游戏内顶部"
},
{
    name: "解压小神器",
    url: "https://res.sjzgxwl.com/pingce/wugui/3/index.html",
    category_id: "6"
},
{
    name: "小兵爱登顶",
    url: "https://mini.ssflashgo.com/wt93/xiaobing/h5/3/web-mobile/index.html",
    category_id: "6"
},
{
    name: "王者枪神-Gm",
    url: "https://file.gugudang.com/res/down/public/p_hegedacheche2/web-mobile/lts2/index.html",
    category_id: "6"
},
{
    name: "体验人生模拟",
    url: "https://testupdate.chutaonet.cn/tiyanrenshengmoni/web_game/web-mobile/index.html",
    category_id: "6"
},
{
    name: "101次重来",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/cardlift/h5/index.html",
    category_id: "6"
},
{
    name: "我的狗头",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/brain6/myDog/web/1.0.0/web-mobile/index.html",
    category_id: "6"
},
{
    name: "暴富一刻",
    url: "https://z10.tanyu.mobi/tanpaixyx/28010/web/1.0.2/web-mobile/index.html",
    category_id: "6"
},
{
    name: "赘婿的逆袭",
    url: "http://d.glf2ym.cn/mHhY4Y",
    category_id: "6"
},
{
    name: "想致富先修路",
    url: "https://game2.sfplay.net/PlantTrees/v6",
    category_id: "6"
},
{
    name: "喵喵人生",
    url: "https://z10.tanyu.mobi/tanpaixyx/28005/web/1.0.1/index.html",
    category_id: "6"
},
{
    name: "当帝王有多好",
    url: "https://security.feishu.cn/link/safety?target=https%3A%2F%2Fwsdw.dengdengkeji.com%2Fcocosv1.1.18%2Fweb-mobile%2Findex.html&scene=ccm&logParams=%7B%22location%22%3A%22ccm_default%22%7D&lang=zh-CN",
    category_id: "6"
},
{
    name: "就要飞跃",
    url: "https://cdn.cdwaterbear.cn/SimulationGame_Web/index.html",
    category_id: "6"
},
{
    name: "王牌老司机",
    url: "https://game-res.tos-cn-beijing.volces.com/qi-huan/wplsj-daren/web-mobile/index.html",
    category_id: "6"
},
{
    name: "小乞丐逆袭记",
    url: "https://mini.ssflashgo.com/wt74/web/1026/web-mobile/index.html",
    category_id: "6"
},
{
    name: "我要当帝皇",
    url: "https://game-res.tos-cn-beijing.volces.com/remote-res/dang-huang-di/web/1.0.1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "广告模拟器",
    url: "https://game-res.tos-cn-beijing.volces.com/web-games/guang-gao-mo-ni-qi/1.0.7/web-mobile/index.html",
    category_id: "6"
},
{
    name: "西游梗传",
    url: "http://littlegame.tomato123.cn/xiyou/index.html",
    category_id: "6"
},
{
    name: "马桶怪物",
    url: "https://res.heigame.com/webRes/MonsterHunterBV1.0.2/index.html",
    category_id: "6"
},
{
    name: "抖个梗",
    url: "https://res.sjzgxwl.com/pingce/DouGeGeng_HH/index.html",
    category_id: "6"
},
{
    name: "洗浴中心模拟器",
    url: "https://game2.sfplay.net/bathing3D/v1",
    category_id: "6"
},
{
    name: "雷电割草",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/leidian_dr_plus/",
    category_id: "6"
},
{
    name: "虚拟解压馆",
    url: "https://mini.ssflashgo.com/wt63/VirtualDecompression/web/v101/web7/web-mobile/index.html",
    category_id: "6"
},
{
    name: "嘻帅大乱斗",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/sandbox3/tangpingmy/xstpdouyin/web-mobile/index.html?_t=1700790540287",
    category_id: "6"
},
{
    name: "方块消除我要很多",
    url: "https://sgame.pkfj.xyz/xs/res/fk_dy/index.html",
    category_id: "6"
},
{
    name: "全民打工人",
    url: "https://cdn.moblazer.com/Office/web2/index.html",
    category_id: "6"
},
{
    name: "脑洞人爱收纳",
    url: "https://maoyumeng.cdn.xiaojing.work/chdl/cydmx/web/web-mobile1/index.html",
    category_id: "6"
},
{
    name: "我打螺丝贼6",
    url: "https://static.zuiqiangyingyu.net/wb_webview/playHammer/playHammer/_wdlszl/web-mobile/index.html",
    category_id: "6"
},
{
    name: "疯狂脑洞找茬人",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/hzwz/fkndzcwh5/index.html",
    category_id: "6"
},
{
    name: "货物柜消一消",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/hwgxyx2/",
    category_id: "6"
},
{
    name: "消消大师2",
    url: "https://games.cdn.dianli100.com/mj/h5/1.0.11/index.html",
    category_id: "6"
},
{
    name: "上学威龙",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/sxwl_tt/",
    category_id: "6"
},
{
    name: "割草吃鸡大乱斗",
    url: "https://cdn17.tomato123.cn/mjGccjdld/web/102/web-mobile/index.html",
    category_id: "6"
},
{
    name: "暴走烧脑",
    url: "https://res3.haotgame.com/cu04/static/BaoZouShaoNao/web-mobile/",
    category_id: "6"
},
{
    name: "翻牌大作战",
    url: "https://games.cdn.dianli100.com/mj/h5/5.0.1/index.html",
    category_id: "6"
},
{
    name: "快来救驾",
    url: "https://games.cdn.dianli100.com/mj/h5/2.0.3/index.html",
    category_id: "6"
},
{
    name: "大侠传说",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/huochairen-brothergo/1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "重生当剑客",
    url: "https://game2.sfplay.net/WulinStickman/v1",
    category_id: "6"
},
{
    name: "居家模拟器",
    url: "https://xiaoxiang-1300577131.cos.ap-nanjing.myqcloud.com/h5Game/web-mobile/index.html",
    category_id: "6"
},
{
    name: "独臂逃生",
    url: "https://cpgame-1259238728.file.myqcloud.com/dbts/web-mobile/index.html",
    category_id: "6"
},
{
    name: "王者打工人",
    url: "https://wwjres.haowan823.cn/xyxh5_test/dgr_test/web5/",
    category_id: "6"
},
{
    name: "大排档模拟器",
    url: "https://game-pkg.dooowin.com/open/g93/2024-01-17/Eky2fxAs/index.html",
    category_id: "6"
},
{
    name: "开个披萨店",
    url: "https://hscdn9.quanmin-game.com/burgerplease/WEB/web_qxzcyj/index.html",
    category_id: "6"
},
{
    name: "爆笑汉字",
    url: "https://mini.ssflashgo.com/wt63/GuHuazhaocha/web/version465/web1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "向往的庄园",
    url: "https://cdn.kunyou88.cn/fanpai/index.html",
    category_id: "6"
},
{
    name: "我是大玩家",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/cp/xp_xcdwj/tt-h5/web-mobile/index.html",
    category_id: "6"
},
{
    name: "反击英雄",
    url: "https://res.xcx.snsfun.com/fjyxWEB_01/",
    category_id: "6"
},
{
    name: "三国哈哈哈",
    url: "https://sghhh.99aly.com/5agamewx/alywx/sghhh/wx/sghhh/web-mobile/index.html",
    category_id: "6"
},
{
    name: "最富枪王",
    url: "https://gamesres.ultralisk.cn/h5_games_daren/174_1732605766874/index.html",
    category_id: "6"
},
{
    name: "全民烧脑新版",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/braintalantSequel/h5_daren/web-mobile/index.html",
    category_id: "6"
},
{
    name: "宠物救援",
    url: "https://cdn.flysheeep.com/ganshaojun/pet/webgl/index.html",
    category_id: "6"
},
{
    name: "奇葩汉字王",
    url: "https://mini.ssflashgo.com/wt62/qpwzw/web/2024050701/index.html",
    category_id: "6"
},
{
    name: "纸上当大侠-Gm",
    url: "https://game2.sfplay.net/PracticeSwords_match/v1.0.5/v1",
    category_id: "6"
},
{
    name: "剧情梗传",
    url: "https://cdn.hanyougame.cn/JuQingGengZhuanGame/h5/staticWeb/webGame/index.html",
    category_id: "6"
},
{
    name: "进击的大鹅/鹅鹅出击-Gm",
    url: "https://cdn.flysheeep.com/noadgame/GoGoGoose/20240326_1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "阵地保卫战",
    url: "http://h5.sm.deniulor.com/policevszombiezombiecity/h5/",
    category_id: "6"
},
{
    name: "疯狂打螺丝-Gm",
    url: "http://www.chengmei.games/daluosi/index.html",
    category_id: "6"
},
{
    name: "玩转文字",
    url: "https://play.qwplay.cn/zstext/",
    category_id: "6"
},
{
    name: "开箱车的二宝",
    url: "https://pub.selpay.cn/games/kxcdeb/na2/index.html",
    category_id: "6"
},
{
    name: "点击右上角第一关和绿色钻石中间位置出现GM",
    url: "https://file.gugudang.com/res/down/public/bzdld/web-mobile/lts2/index.html",
    category_id: "6"
},
{
    name: "我的乡村生活",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/web_xjgt3/",
    category_id: "6"
},
{
    name: "糖果爱消消",
    url: "https://cocos-4gxdj2aj4ef4821e-1324759429.tcloudbaseapp.com/thl/web-mobile/index.html",
    category_id: "6"
},
{
    name: "有间烧烤店-电脑版",
    url: "http://wxclient.gzqidong.cn/GunFactory/operateTest_desk/index.html",
    category_id: "6"
},
{
    name: "我是天师",
    url: "https://mkw.youngwingtec.com/project_config/daoshi2_pro/",
    category_id: "6"
},
{
    name: "无尽消消消-乌龟版",
    url: "https://res.wqop2018.com/partner/hzdy/gbzwb/H5_TuetleBlindBox/9_13_1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "无尽消消消",
    url: "https://cdn.smartfoxgames2.xyz/tetris/tetris_wb.html",
    category_id: "6"
},
{
    name: "拳王俱乐部",
    url: "https://res.sjzgxwl.com/CHZ/GameWeb/QG_zx20240508/index.html",
    category_id: "6"
},
{
    name: "我的保卫战",
    url: "https://res.innofuns.com/h5/WY_TDYX/web-mobile/",
    category_id: "6"
},
{
    name: "这关真难过",
    url: "https://game2.sfplay.net/Bankruptcy/v1.2.1/zgzng/v1/",
    category_id: "6"
},
{
    name: "我是富三代",
    url: "https://game2.sfplay.net/Bankruptcy/v1.2.0/v3",
    category_id: "6"
},
{
    name: "成功人生逆袭记",
    url: "https://game2.sfplay.net/Bankruptcy/v1.1.9/v1/",
    category_id: "6"
},
{
    name: "摸鱼水族馆",
    url: "https://qtweb.qtjoy.com/games/AquariumLand/index.html",
    category_id: "6"
},
{
    name: "猫咪前哨站",
    url: "https://game2.sfplay.net/CatShotRogou/v1.0.0/v2/",
    category_id: "6"
},
{
    name: "牛了个牛",
    url: "https://file.gugudang.com/res/down/public/p_daluosi/web-mobile/lts2/index.html",
    category_id: "6"
},
{
    name: "白手起家模拟器-Gm",
    url: "https://daren.glifegame.com/sjzw/index.html",
    category_id: "6"
},
{
    name: "十万八千里-Gm",
    url: "https://shiwan-5ggvtzoh11becc8a-1251260145.tcloudbaseapp.com/shiwan/web-mobile/index.html",
    category_id: "6"
},
{
    name: "我就要消除",
    url: "https://cdn.flysheeep.com/noadgame/linkBlock/20241125_1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "解压猫咪-看起来很怪",
    url: "https://cdn.smartfoxgames2.xyz/cats/web_h5/1.3.38_sf2/web-mobile/tyedex.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "疯狂商业街",
    url: "https://game2.sfplay.net/CounterattackDiary/v1/",
    category_id: "6"
},
{
    name: "开心挪挪车",
    url: "https://idata.igame58.com/h5/KXNNC/web-mobile/index.html",
    category_id: "6"
},
{
    name: "废柴成仙录",
    url: "http://h5game.thesmartmelon.com/index.html",
    category_id: "6"
},
{
    name: "疯狂逆袭记-Gm",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/zhise/qmnx/20230914/index.html",
    category_id: "6"
},
{
    name: "梦幻都市",
    url: "https://oss.tobetopone.com/h5/dream_city/",
    category_id: "6"
},
{
    name: "彩色世界",
    url: "https://cdn.cdwaterbear.cn/Storyteller/web/",
    category_id: "6"
},
{
    name: "养鸡日记",
    url: "https://ylgjcdn.xyx.mmhygame.com/ylgj/web/index.html",
    category_id: "6"
},
{
    name: "超级解压馆",
    url: "https://res.kampfiregames.cn/coinSort/web7/index.html",
    category_id: "6"
},
{
    name: "文字无双",
    url: "https://zzxygame.com/hzws/",
    category_id: "6"
},
{
    name: "动动大脑瓜",
    url: "https://zgbjdcdn.langqugame.com/dddng/web-mobile/",
    category_id: "6"
},
{
    name: "脑力挑战赛",
    url: "https://games.qtjoy.com/games/Stratego/index.html",
    category_id: "6"
},
{
    name: "脑洞大师傅",
    url: "https://z10.tanyu.mobi/tanpaixyx/28004/new-web/index.html",
    category_id: "6"
},
{
    name: "二狗逆袭记",
    url: "https://littlegame.tomato123.cn/unbox/index.html",
    category_id: "6"
},
{
    name: "疯狂的老板",
    url: "https://oss.zhejing.tech/webH5/career-tiktok/index.html",
    category_id: "6"
},
{
    name: "当总裁有多爽",
    url: "https://oss.zhejing.tech/webH5/career-tiktok-zongcaiweiwu/index.html",
    category_id: "6"
},
{
    name: "你的选择没有错",
    url: "https://oss.tobetopone.com/h5/xrwdrw/",
    category_id: "6"
},
{
    name: "疯狂推推推",
    url: "https://cdn.cdwaterbear.cn/DeliverIt3D/web/index.html",
    category_id: "6"
},
{
    name: "加油小车车",
    url: "https://cdn.hiluluke.com/noad/xiaocheche/index.html",
    category_id: "6"
},
{
    name: "开个大工厂-Gm",
    url: "https://cdn.hanyougame.cn/ChongFanJiuLing/webgame/web-mobile/index.html",
    category_id: "6"
},
{
    name: "全场你最牛",
    url: "http://z8.tanyu.mobi/tanpaixyx/daerguang/web/1.0.6/index.html",
    category_id: "6"
},
{
    name: "我锤子贼6",
    url: "https://cdn.flysheeep.com/jiangnengren/ScrewJamNoAd/20240530_1/NoAdRelease/index.html",
    category_id: "6"
},
{
    name: "沙雕出击",
    url: "https://qihuanmao.cdn.xiaojing.work/ShaDiaoGame/WebNoAds/web-mobile/index.html",
    category_id: "6"
},
{
    name: "小兵快冲鸭",
    url: "https://candymatch-update.fjbgwl.com/web-mobile1/index.html",
    category_id: "6"
},
{
    name: "看不见的真相",
    url: "https://kbjzx-byte-1317242322.cos.ap-guangzhou.myqcloud.com/webgl/index.html",
    category_id: "6"
},
{
    name: "进击的汉字",
    url: "https://cdn.hanyougame.cn/JjdhzGame/h5/staticWeb/webGame/index.html",
    category_id: "6"
},
{
    name: "植物守卫战",
    url: "https://game2.sfplay.net/CatSentinelHP/PVZ/v1",
    category_id: "6"
},
{
    name: "我的纸飞机",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/zfjbag_zlj_dr/",
    category_id: "6"
},
{
    name: "最强脑洞解谜",
    url: "https://res5.haotgame.com/cu04/static/zuiqiangnaodongjiemi_zb/web-mobile/",
    category_id: "6"
},
{
    name: "我智商爆表",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/cp/tt_wzwz/puke/wozhishangbaobiao/index.html",
    category_id: "6"
},
{
    name: "愤怒的黄瓜",
    url: "https://oss.tobetopone.com/h5/fndYellowG_H5",
    category_id: "6"
},
{
    name: "疯狂转圈圈",
    url: "https://online.xcx.snsfun.com/fkzqqwebnew/",
    category_id: "6"
},
{
    name: "觉醒吧侦探",
    url: "https://gametos.keepmobi.com/remote-res/re-geng-da-bao-zha/web/1.0.48_wqer/web-mobile/index.html",
    category_id: "6"
},
{
    name: "美味的食谱",
    url: "https://res.heigame.com/webRes/GrandmaFoodV2.5.3/index.html",
    category_id: "6"
},
{
    name: "我还没上车啊",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/justeliminateit/car/h5/index.html",
    category_id: "6"
},
{
    name: "我玩汉字贼6",
    url: "http://hcstatic.game.jingyougz.com/test/v32/web-mobile/index.html?v1.0.3222222",
    category_id: "6"
},
{
    name: "超级找茬大师",
    url: "https://res3.haotgame.com/cu04/static/chaojizhaochadashi/web-mobile/",
    category_id: "6"
},
{
    name: "喵星人爱收纳",
    url: "https://maoyumeng.cdn.xiaojing.work/chdl/mxrasn/remote/web/web-mobile/index.html",
    category_id: "6"
},
{
    name: "超级收纳馆",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/zhise/cjjyg/web/index.html",
    category_id: "6"
},
{
    name: "收纳时光",
    url: "https://webgame-6gzwg8ti52e46012-1310209978.tcloudbaseapp.com/109snsg/web-mobile/index.html",
    category_id: "6"
},
{
    name: "割断那根绳",
    url: "https://h5game.godricc.com/out/cutjam/202406201932/index.html",
    category_id: "6"
},
{
    name: "明星养成手册",
    url: "https://account.api.snsfun.com/h5game/mxycsc1.0.1/index.html",
    category_id: "6"
},
{
    name: "明星店长",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/peopleshop/web-mobile/index.html",
    category_id: "6"
},
{
    name: "挑战烧脑",
    url: "http://packages.tobetopone.com/webGame/tzsnNeU66/",
    category_id: "6"
},
{
    name: "饥荒不慌",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/jhbh_dr/",
    category_id: "6"
},
{
    name: "海王的飞车世界",
    url: "https://static.game.jingyougz.com/fishisland_h5/car/",
    category_id: "6"
},
{
    name: "家园物语",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/sandbox3/TPDG/web-mobile/index.html",
    category_id: "6"
},
{
    name: "这一关好难",
    url: "https://qtweb.qtjoy.com/games/Staple/index.html",
    category_id: "6"
},
{
    name: "魔兽骑士",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/mjqs1/",
    category_id: "6"
},
{
    name: "猪哥的幸福人生",
    url: "https://cdn.zhang-xian.com/cocos-raising-pigs/wx-mini-game.qiniu.cut-grass/web-mobile-tt/1.3.4/index.html",
    category_id: "6"
},
{
    name: "逆袭大明星-Gm",
    url: "https://res.innofuns.com/h5/WY_ZFDH/web-mobile/",
    category_id: "6"
},
{
    name: "开个供销社",
    url: "https://www.lekonghd.cn/kggxs/1.5.8/",
    category_id: "6"
},
{
    name: "活到大结局",
    url: "https://h5-1258110380.file.myqcloud.com/test2/pdttt/index.html",
    category_id: "6"
},
{
    name: "怪物猎杀狂暴版",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/moritegong/1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "三国哈哈哈2",
    url: "https://sghhh2-cdn.666333999.top/web/index.html",
    category_id: "6"
},
{
    name: "模拟大作战-Gm",
    url: "https://res.wqop2018.com/partner/cdbhy/mndzz/Ancestor/index.html",
    category_id: "6"
},
{
    name: "美少女安琪拉",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/luandouxiaojiejie1/3/web-mobile/index.html",
    category_id: "6"
},
{
    name: "加减大师",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/ded6b1d870c72218f5af7470488c1624/index.html",
    category_id: "6"
},
{
    name: "精英泡泡堂",
    url: "https://static.zuiqiangyingyu.net/wb_webview/sandbox3/sheep3d/h5/web-mobile/index.html?_t=1706268733511",
    category_id: "6"
},
{
    name: "脑洞汉字王",
    url: "https://cdn-corp.cygame666.cn/brainHole/client/web/web-mobile/index.html",
    category_id: "6"
},
{
    name: "文字大状元",
    url: "https://cdn.flysheeep.com/noadgame/ChineseNumberOne/20240129b/web-mobile/index.html",
    category_id: "6"
},
{
    name: "薯条冲冲冲",
    url: "https://res.xcx.snsfun.com/stccc/",
    category_id: "6"
},
{
    name: "嘻帅打螺丝",
    url: "https://static.zuiqiangyingyu.net/wb_webview/playHammer/playHammer/_xishui/web-mobile/index.html",
    category_id: "6"
},
{
    name: "勇气大挑战",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/H5/yqdtz/web-mobile/index.html",
    category_id: "6"
},
{
    name: "素人改造师",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/auto_upload_srgzs/web-mobile/?time=1703834554284",
    category_id: "6"
},
{
    name: "怪谈研究所",
    url: "https://guiwuzhe-1257957916.file.myqcloud.com/strangeTalk/web/index.html",
    category_id: "6"
},
{
    name: "真实卡车模拟",
    url: "https://z10.tanyu.mobi/tanpaixyx/chezi/web/1.0.1/index.html",
    category_id: "6"
},
{
    name: "一起来钻地",
    url: "https://play.qwplay.cn/zuandi/",
    category_id: "6"
},
{
    name: "离谱的经历",
    url: "https://mayahudong-1253544343.file.myqcloud.com/cdn/lpdjl/web/index.html",
    category_id: "6"
},
{
    name: "解绳小能手",
    url: "https://cdn.flysheeep.com/ganshaojun/RopeKiller/web5/index.html",
    category_id: "6"
},
{
    name: "我跑酷贼6",
    url: "https://z10.tanyu.mobi/tanpaixyx/14001/web/1.0.5/index.html",
    category_id: "6"
},
{
    name: "苦命打工人-GM",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/sandbox3/tangpingmy/web-mobile/index.html",
    category_id: "6"
},
{
    name: "双枪王者",
    url: "https://assets.tinyfun.online/game/sale/double-guns/latest/index.html",
    category_id: "6"
},
{
    name: "老铁来一杯",
    url: "https://mini.ssflashgo.com/stonesh/hh/web/newhanzi/?time=1712807385034",
    category_id: "6"
},
{
    name: "古董大师模拟器",
    url: "https://game2.sfplay.net/gddsmnq/v1",
    category_id: "6"
},
{
    name: "看谁能通关",
    url: "https://res.wqop2018.com/partner/hzdy/lxcb/NoAD/202411271650/index.html",
    category_id: "6"
},
{
    name: "打螺丝达人",
    url: "https://cdn.flysheeep.com/noadgame/Screw2D/240528_1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "爱上拧螺丝",
    url: "https://res.cjs001.com/h5/asnls/ks20240708/web/index.html",
    category_id: "6"
},
{
    name: "反转电玩厅",
    url: "https://qcdn.bdoggame.com/ttgame/reversePlayland/web/index.html",
    category_id: "6"
},
{
    name: "我又长脑子了",
    url: "https://final-puzzle-res.exploent.com/h5/1.0.0/index.html",
    category_id: "6"
},
{
    name: "翻大饼",
    url: "https://flight.zhinuoshuzi.com/h5/fdb/index.php",
    category_id: "6"
},
{
    name: "汉字大招版",
    url: "https://game.csquyou.com/HanziVertical11/dazhao_live.html?v=1.0.0",
    category_id: "6"
},
{
    name: "炫斗奇兵",
    url: "https://game2.sfplay.net/CatShot/v1.0.0/v1",
    category_id: "6"
},
{
    name: "疯狂小兵",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/sandbox3/TPDG/zqxb/web-mobile/index.html",
    category_id: "6"
},
{
    name: "万万没想到-未来的你-还能这样玩",
    url: "https://oss.tobetopone.com/h5/eschaton/",
    category_id: "6"
},
{
    name: "消除不能停",
    url: "https://game.niudu.com/drlj/Linking/web/web-mobile/index.html",
    category_id: "6"
},
{
    name: "海上攻防战",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/partner/grcy/hsgfz/H5/0.2/index.html",
    category_id: "6"
},
{
    name: "神脑洞",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/justeliminateit/snd/h5/index.html",
    category_id: "6"
},
{
    name: "智商叠高高",
    url: "http://h5.sm.deniulor.com/hexasort/h5/",
    category_id: "6"
},
{
    name: "载具大挑战",
    url: "http://h5.sm.deniulor.com/idleracer/h5/",
    category_id: "6"
},
{
    name: "采矿大老板",
    url: "http://h5.sm.deniulor.com/trainminer/h5/",
    category_id: "6"
},
{
    name: "疯狂的小喵",
    url: "http://h5.sm.deniulor.com/superslime/h5/",
    category_id: "6"
},
{
    name: "疯狂炮塔",
    url: "http://h5.sm.deniulor.com/defensetowers/h5/",
    category_id: "6"
},
{
    name: "收纳模拟器",
    url: "http://112.74.33.201:8080/cnmnq/",
    category_id: "6"
},
{
    name: "救救这头牛",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/pigrun/h5-tt2/index.html",
    category_id: "6"
},
{
    name: "无尽魔兽传说",
    url: "https://cdn17.tomato123.cn/web-mobile2/index.html",
    category_id: "6"
},
{
    name: "打螺丝大师",
    url: "https://cdn.flysheeep.com/noadgame/screwMaster/20240515/web-mobile/index.html",
    category_id: "6"
},
{
    name: "纸上战争",
    url: "https://demo-game-1305175708.cos.ap-chongqing.myqcloud.com/a3_paperwar155312/index.html",
    category_id: "6"
},
{
    name: "无敌消消消",
    url: "https://cdn.shyzgame.com/wdxxx/index.html",
    category_id: "6"
},
{
    name: "眼神不错哟",
    url: "https://ykres.yigomob.com/noad/EyeNotBad/1.3.47_0608/web-mobile/index.html",
    category_id: "6"
},
{
    name: "想致富先种树",
    url: "https://game2.sfplay.net/PlantTrees/v2/",
    category_id: "6"
},
{
    name: "入侵异世界",
    url: "https://game2.sfplay.net/CatSentinelHP/TP/v1.2.4/v3/",
    category_id: "6"
},
{
    name: "脑洞全明星",
    url: "https://www.lekonghd.cn/ndqmx3D/1.0.8/",
    category_id: "6"
},
{
    name: "超能毒液人",
    url: "http://debug.pumpkinheadgame.online/Run",
    category_id: "6"
},
{
    name: "又菜又爱玩",
    url: "https://game2.sfplay.net/SweepTen/v6/",
    category_id: "6"
},
{
    name: "又菜又爱玩",
    url: "https://game2.sfplay.net/Bankruptcy/v1.2.0/v3",
    category_id: "6"
},
{
    name: "摸鱼王者",
    url: "https://cdngame.shuling.vip/BianJuDaShi/MoYuWangZhe/h5/web-mobile/index.html?1.0.8",
    category_id: "6"
},
{
    name: "食神再婚",
    url: "https://daren.glifegame.com/jtmsnx/index.html",
    category_id: "6"
},
{
    name: "找出不对劲",
    url: "https://res.wqop2018.com/partner/hzdy/gbzwb/H5_zcbdj/5_30/web-mobile/index.html",
    category_id: "6"
},
{
    name: "超凡智商",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/cp/tt_wzwz/chaofanzhishang1.0.0/index.html",
    category_id: "6"
},
{
    name: "鲨鱼风暴",
    url: "https://maoyumeng.club/HYTG/web/web-mobile16/index.html",
    category_id: "6"
},
{
    name: "超级房间-Gm",
    url: "https://file.gugudang.com/res/down/public/p_hegedacheche/web-mobile/lts/index.html",
    category_id: "6",
    remok: "游戏内点击金币红钻宝石无限增加"
},
{
    name: "巨物大对决-Gm",
    url: "https://file.gugudang.com/res/down/public/p_hegedacheche/web-mobile/lts/index.html",
    category_id: "6",
    remok: "游戏内点击金币红钻宝石无限增加"
},
{
    name: "就是比你牛",
    url: "https://cdn.hanyougame.cn/chixiwodiyi/web-mobile/index.html",
    category_id: "6"
},
{
    name: "地下城王者",
    url: "https://jjddz-wxcdn.xyx.mmhygame.com/jjddz/web/index.html",
    category_id: "6"
},
{
    name: "无聊人生",
    url: "https://game-res.tos-cn-beijing.volces.com/web-games/wu_liao_ren_sheng/1.0.3hhg/web-mobile/index.html",
    category_id: "6"
},
{
    name: "出来溜溜-Gm",
    url: "https://game.csquyou.com/TurtleMatching11/webad.html?v=1.0.0",
    category_id: "6"
},
{
    name: "脑回路打结",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/game/llk/index.html",
    category_id: "6"
},
{
    name: "口袋蚁穴",
    url: "https://cdn.moblazer.com/antcolony/web/index.html",
    category_id: "6"
},
{
    name: "谁是冠军",
    url: "https://aaa.puffergames.com/GameServerData2/unity/unity_lshjy/webgl_ssgj/index.html",
    category_id: "6"
},
{
    name: "玩了个锤子",
    url: "https://static.zuiqiangyingyu.net/wb_webview/playHammer/playHammer/_jingjianZb/web-mobile/index.html",
    category_id: "6"
},
{
    name: "玩了个锤子",
    url: "http://d.glf2ym.cn/r7HHPi",
    category_id: "6"
},
{
    name: "救救这只猪",
    url: "http://d.glf2ym.cn/wkaCK4",
    category_id: "6"
},
{
    name: "沉浸式生活",
    url: "https://cdn.hanyougame.cn/Common/Game/ImmersiveLife/webgame_test/web-mobile/index.html",
    category_id: "6"
},
{
    name: "逃脱大师",
    url: "https://game-res.tos-cn-beijing.volces.com/web-games/tao_tuo_da_shi/1.0.8/web-mobile/index.html",
    category_id: "6"
},
{
    name: "收租生活日记",
    url: "http://d.glf2ym.cn/fe2Cz5",
    category_id: "6"
},
{
    name: "贱走偏风",
    url: "https://oppomini.eqmobi.com/wt63/jianzoupianfeng/web/version103/web3/web-mobile/",
    category_id: "6"
},
{
    name: "翡翠大王",
    url: "http://h5.smallgame.top/fcdw202405171",
    category_id: "6"
},
{
    name: "猫咪前哨战",
    url: "https://game2.sfplay.net/CatSentinelForeign/v1",
    category_id: "6"
},
{
    name: "菜狗行不行-GM",
    url: "https://xiaogou-update.fjbgwl.com/web-mobileCG2/index.html",
    category_id: "6"
},
{
    name: "老铁救救我",
    url: "https://static.zuiqiangyingyu.net/wb_webview/brain6/buddySaveMe/web/web-mobile/index.html",
    category_id: "6"
},
{
    name: "非凡的大多数-Gm",
    url: "https://tos.zaml2018.com/index.html?app=outer_a7049830eef467d37352b3fcab1e47c5",
    category_id: "6"
},
{
    name: "叫我找茬大师",
    url: "https://qianzong.darknight.games/naolizhaochawang/web/0.0.3/index.html",
    category_id: "6"
},
{
    name: "奸商模拟器-Gm",
    url: "https://kjmnq-1259238728.file.myqcloud.com/test/index.html",
    category_id: "6"
},
{
    name: "全民剧本大师",
    url: "http://cm.darknight.games/scriptMaster/web-mobile/index.html",
    category_id: "6"
},
{
    name: "给娘娘请安",
    url: "https://daren.glifegame.com/hggl/index.html",
    category_id: "6"
},
{
    name: "这是解谜吗",
    url: "https://tos.zaml2018.com/index.html?app=gm_7fefb8165695e3d436a9cb1cc59a5c21",
    category_id: "6"
},
{
    name: "喵喵爱吃瓜",
    url: "http://112.74.33.201:8080/tcs2/",
    category_id: "6"
},
{
    name: "夜市之王",
    url: "https://www.lekonghd.cn/yszw/0.0.6/",
    category_id: "6"
},
{
    name: "卡车模拟器",
    url: "https://game2.sfplay.net/thecar",
    category_id: "6"
},
{
    name: "超能整活",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/zhise/cnzh/web/index.html",
    category_id: "6"
},
{
    name: "超级人生赢家",
    url: "https://ume-1306566067.cos.ap-guangzhou.myqcloud.com/games/investment-counterattack/index.html",
    category_id: "6"
},
{
    name: "疯狂逆袭记-Gm",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/zhise/qmnx/20230914/index.html",
    category_id: "6"
},
{
    name: "脑洞小老弟",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/eliminatingstacking/h5-5/index.html",
    category_id: "6"
},
{
    name: "就我手快",
    url: "https://ddwalk-1301030645.cos.ap-guangzhou.myqcloud.com/webgame/CarMove_DY/v7/index.html",
    category_id: "6"
},
{
    name: "纸上发育",
    url: "https://antplay888.com/huochairen/1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "赘婿逆袭记",
    url: "https://hscdn1.quanmin-game.com/game/CountOnMe/test/202310231600/web-mobile/index.html",
    category_id: "6"
},
{
    name: "文字王者",
    url: "http://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/cp/tt_wzwz/web-mobile/index.html",
    category_id: "6"
},
{
    name: "自然灾害模拟器",
    url: "https://cdn.cdwaterbear.cn/NaturalDisasterSimulator/web/index.html",
    category_id: "6"
},
{
    name: "一起造个车",
    url: "https://game.haiwanwl.com/yqzgc_bytedance/",
    category_id: "6"
},
{
    name: "开心美食店-穷小子创业记",
    url: "https://hscdn9.quanmin-game.com/burgerplease/tt_qxzcyj1/web4/index.html",
    category_id: "6"
},
{
    name: "喵星神射手",
    url: "https://game.haiwanwl.com/gohomecat/",
    category_id: "6"
},
{
    name: "城市打拼记",
    url: "https://hscdn1.quanmin-game.com/game/CityCareer/web/202311301530/web-mobile/index.html",
    category_id: "6"
},
{
    name: "机器人大挑战",
    url: "https://static.zuiqiangyingyu.net/wb_webview/myDog/robotBattle/daren/web-mobile/index.html",
    category_id: "6"
},
{
    name: "遇事不要慌",
    url: "https://game2.sfplay.net/AnotherBottle1/v6/",
    category_id: "6"
},
{
    name: "搞钱高手",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/H5/tt_gqgs/web-mobile/index.html",
    category_id: "6"
},
{
    name: "当帝王有多好",
    url: "https://security.feishu.cn/link/safety?target=https%3A%2F%2Fwsdw.dengdengkeji.com%2Fcocosv1.1.18%2Fweb-mobile%2Findex.html&scene=ccm&logParams=%7B%22location%22%3A%22ccm_default%22%7D&lang=zh-CN",
    category_id: "6"
},
{
    name: "天天爱动脑",
    url: "http://tos.wqop2018.com/mp/game/web/gm/61cd140befe84cb817666aa7b814f851/",
    category_id: "6"
},
{
    name: "我为节奏狂",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/wwjzk/",
    category_id: "6"
},
{
    name: "又菜又爱玩",
    url: "https://candymatch-update.fjbgwl.com/nuoduiduiweb/index.html",
    category_id: "6"
},
{
    name: "我的脑洞无限",
    url: "https://langqu.darknight.games/web-mobile/index.html",
    category_id: "6"
},
{
    name: "同福小吃街",
    url: "https://game2.sfplay.net/TFFoodCourt/v1",
    category_id: "6"
},
{
    name: "这鱼塘我包了",
    url: "https://wenshi-static.game.jingyougz.com/minigame/h5/pond/index.html",
    category_id: "6"
},
{
    name: "魔兽进化论2",
    url: "http://littlegame.tomato123.cn/msjhl/index.html",
    category_id: "6"
},
{
    name: "烧脑瓶子",
    url: "https://static.zuiqiangyingyu.net/wb_webview/shaonaogame/h5/index.html",
    category_id: "6"
},
{
    name: "当食神有多好-Gm",
    url: "https://wydss-byte-tuyue-1317242322.cos.ap-guangzhou.myqcloud.com/dssydh/index.html",
    category_id: "6"
},
{
    name: "橡皮火柴人",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/matchStickMan/rubber_matchstick_man/h5_daren/web-mobile/index.html",
    category_id: "6"
},
{
    name: "画线火柴人",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/matchStickMan/h5_daren/web-mobile/index.html?a=454455568786",
    category_id: "6"
},
{
    name: "图文世界",
    url: "https://oss.tobetopone.com/h5/twsj_test/",
    category_id: "6"
},
{
    name: "肥鸡跳跳",
    url: "https://www.wesane.com/game/2071/",
    category_id: "6"
},
{
    name: "找下猫",
    url: "https://smartfox.top/cats/h5/1.2.40/web-mobile/index.html",
    category_id: "6"
},
{
    name: "小小芝麻官",
    url: "http://d.glf2ym.cn/8RRAy6",
    category_id: "6"
},
{
    name: "小卡拉逆袭记",
    url: "https://cdngame.shuling.vip/BianJuDaShi/KaLaNiXi/H5/web-mobile/index.html?V1.0.1",
    category_id: "6"
},
{
    name: "找就完事了",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/hzwz/zjwslh5/index.html",
    category_id: "6"
},
{
    name: "愤怒的食物",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/plants/2/web-mobile/index.html",
    category_id: "6"
},
{
    name: "典籍里的文字",
    url: "https://oss.tobetopone.com/h5/magical_word/",
    category_id: "6"
},
{
    name: "牛了个牛",
    url: "https://file.gugudang.com/res/down/public/p_lianliankan/web-mobile/lts2/index.html?t=1732858051655",
    category_id: "6"
},
{
    name: "煮酒论英雄",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/qigaidw/3/web-mobile/index.html",
    category_id: "6"
},
{
    name: "文字大脑洞",
    url: "https://game-static.heicheng51.com/gm/wzdnd/web-mobile/index.html?v=1.0.1",
    category_id: "6"
},
{
    name: "破晓逃亡",
    url: "https://hanzdlddl.leiting.com/escape/web-mobile/index.html",
    category_id: "6"
},
{
    name: "完美选择",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/ly_cxnxj_dblm/",
    category_id: "6"
},
{
    name: "过河大师",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/ghds_1.0.24_test127/",
    category_id: "6"
},
{
    name: "披萨工厂-Gm",
    url: "https://cdn-pizzafactory.cygame666.cn/webTT/webTT0/index.html",
    category_id: "6"
},
{
    name: "解压高手",
    url: "https://antplay888.com/daBaoDaShi/t3/web-mobile/index.html",
    category_id: "6"
},
{
    name: "人间诡视",
    url: "http://littlegame.tomato123.cn/peep/index.html",
    category_id: "6"
},
{
    name: "武侠重生记",
    url: "https://cdn.datichengyu.com/yswl/h5/index.html",
    category_id: "6"
},
{
    name: "酱香咖啡厅",
    url: "https://qtweb.qtjoy.com/games/CoffeeBigPlayer/index.html",
    category_id: "6"
},
{
    name: "杯子消消消",
    url: "https://cpgame-1259238728.file.myqcloud.com/bzxxx/web-mobile/index.html",
    category_id: "6"
},
{
    name: "废土前哨站",
    url: "https://game2.sfplay.net/CatSentinelHP/Doomsday",
    category_id: "6"
},
{
    name: "解压空间",
    url: "https://cpgame-1259238728.file.myqcloud.com/jykj/noad/index.html",
    category_id: "6"
},
{
    name: "小小收纳",
    url: "https://z10.tanyu.mobi/tanpaixyx/18002/web/1.0.170/index.html",
    category_id: "6"
},
{
    name: "最强大挑战",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/H5/tt_zqdtz/web-mobile/index.html",
    category_id: "6"
},
{
    name: "地板英雄",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/yizhichaosheng/1/web-mobile/index.html",
    category_id: "6"
},
{
    name: "汉字世界",
    url: "https://mini.ssflashgo.com/wt62/hzsj/web/2023122903/web-mobile/index.html",
    category_id: "6"
},
{
    name: "攻防兼备",
    url: "https://packages.tobetopone.com/webGame/gsjb_H5_2/",
    category_id: "6"
},
{
    name: "找茬黄金瞳",
    url: "https://xcx-cdn-bsy.tongchuanggame.com/goldeneyes/web-mobile/index.html?_v=1.0.5",
    category_id: "6"
},
{
    name: "情商天花板",
    url: "https://oss.tobetopone.com/h5/qsthb_h5",
    category_id: "6"
},
{
    name: "最佳审判",
    url: "http://packages.tobetopone.com/webGame/zjsp_1/",
    category_id: "6"
},
{
    name: "熊熊要回家",
    url: "http://www.91zyx.com:8080/",
    category_id: "6"
},
{
    name: "最强球爱",
    url: "https://static-cdn.51haodong.com/oppo/helpdate2-oppo/1/index.html",
    category_id: "6"
},
{
    name: "趣味烧脑",
    url: "https://mini.ssflashgo.com/stonesh/zxm/qwsn/web20231212/web-mobile/index.html",
    category_id: "6"
},
{
    name: "飞行球",
    url: "https://fs-zy.hudong.city/zy12/web/index.html",
    category_id: "6"
},
{
    name: "赚钱养女儿",
    url: "https://www.lekonghd.cn/wyynr2/0.1.1/",
    category_id: "6"
},
{
    name: "跟我一起守",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/partner/cdly/xqlz/20240815/index.html",
    category_id: "6"
},
{
    name: "往后牌牌",
    url: "https://renyounew.renyouwangluo.cn/remoteGame/wangHouPaiPaiNewGame/",
    category_id: "6"
},
{
    name: "找个茬茬",
    url: "https://socho-1302513287.cos.ap-nanjing.myqcloud.com/shygt/res_web/web-mobile/index.html",
    category_id: "6"
},
{
    name: "收手吧阿祖-Gm",
    url: "https://www.higame.geek-meta.com/develop2/003_test/001_dgrm/h5_mobile/v1.01/index.html",
    category_id: "6"
},
{
    name: "头脑天花板",
    url: "https://oss.tobetopone.com/h5/mmxc_h5/",
    category_id: "6"
},
{
    name: "粉末沙盒",
    url: "https://static.zuiqiangyingyu.net/wb_webview/SaveTheGirl/fenmogame/web-mobile/index.html",
    category_id: "6"
},
{
    name: "脑汁分你一半",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/partner/xp/nzfnyb/0.0.9/web-mobile/index.html",
    category_id: "6"
},
{
    name: "真让人头大-Gm",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/bgcsl_drwg/",
    category_id: "6"
},
{
    name: "猎梦前哨站",
    url: "https://game2.sfplay.net/CatSentinelHP/daoshi/v1",
    category_id: "6"
},
{
    name: "愤怒的植物",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/plants/2/web-mobile/index.html",
    category_id: "6"
},
{
    name: "全民找不动",
    url: "https://res.wqop2018.com/zhise/fkdy/chtest/qmzbd6/index.html",
    category_id: "6"
},
{
    name: "不可名状的奇境",
    url: "https://candymatch-update.fjbgwl.com/xiongmao/2024012201/index.html",
    category_id: "6"
},
{
    name: "箱子管理大师",
    url: "https://oss.tobetopone.com/h5/xzglds1/",
    category_id: "6"
},
{
    name: "洗浴城大玩家",
    url: "https://qtweb.qtjoy.com/games/spaBigPlayer/index.html",
    category_id: "6"
},
{
    name: "胡了个牌",
    url: "https://oss.tobetopone.com/h5/mlgj/",
    category_id: "6"
},
{
    name: "钱袋子保卫战",
    url: "https://static-cdn.zhouhy.net/h5/project/qingdu/web/qigaidw/3/web-mobile/index.html",
    category_id: "6"
},
{
    name: "快乐合方块",
    url: "https://leqi-1259286428.file.myqcloud.com/games/spherenoad/index.html",
    category_id: "6"
},
{
    name: "拿下一座城",
    url: "https://game2.sfplay.net/PracticeSwords/v1.1.5/v4/",
    category_id: "6"
},
{
    name: "锻剑开天",
    url: "https://game2.sfplay.net/PracticeSwords_match/DJKT/v1.0.2/v2/",
    category_id: "6"
},
{
    name: "爆棚的智商",
    url: "https://guiwuzhe-1257957916.file.myqcloud.com/explodingIQ/res/web-mobile/index.html",
    category_id: "6"
},
{
    name: "咱家有个宝",
    url: "https://www.yzqgamefun.com/assets/ZanjiaHaveABaby/web/version570/web-mobile/index.html",
    category_id: "6"
},
{
    name: "看你怎么玩",
    url: "https://cdn-corp.cygame666.cn/brainHole/client/web/web-mobile8/index.html",
    category_id: "6"
},
{
    name: "废物成神-GM",
    url: "https://file.gugudang.com/res/down/public/p_hegedacheche/web-mobile/lts/index.html",
    category_id: "6"
},
{
    name: "这就是骚操作",
    url: "https://res.kampfiregames.cn/runFish4/vestBag1/index.html",
    category_id: "6"
},
{
    name: "逃出小屋",
    url: "https://game.niudu.com/drlj/tpdmm/web_tcxw/web-mobile/index.html",
    category_id: "6"
},
{
    name: "我的大脑会拐弯",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/H5/wddnhgw/web-mobile/index.html",
    category_id: "6"
},
{
    name: "纸上攻防战",
    url: "https://game2.sfplay.net/CatSentinelHp/match/v1.0.1/v2",
    category_id: "6"
},
{
    name: "疯狂成团",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/cp/tt_wzwz/fkct/fkct1.0.1/index.html",
    category_id: "6"
},
{
    name: "梦想消除-Gm",
    url: "http://h5.sm.deniulor.com/busjam/h5_mxxc/",
    category_id: "6"
},
{
    name: "饥荒不慌",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/jhbh_dr/",
    category_id: "6"
},
{
    name: "迷你军团战争",
    url: "https://cpgame-1259238728.file.myqcloud.com/miniBattle/test/index.html",
    category_id: "6"
},
{
    name: "背后的真相",
    url: "https://qcdn.bdoggame.com/ttgame/joWeb/webVer/index.html",
    category_id: "6"
},
{
    name: "素人改造师",
    url: "https://renyousome.renyouwangluo.cn/remoteGame/bgcsl_drwg/",
    category_id: "6"
},
{
    name: "货车停车场",
    url: "https://cdn.cdwaterbear.cn/CargoTruckParking3D/web/index.html",
    category_id: "6"
},
{
    name: "天天套圈",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/zhise/tttq/h5/web-mobile/index.html",
    category_id: "6"
},
{
    name: "寻物小达人",
    url: "https://oppomini.eqmobi.com/wt63/web_mobile/web-mobile/",
    category_id: "6"
},
{
    name: "你有我快吗",
    url: "https://cdn.198434.com/h5/zhise/nywkm202403211318/",
    category_id: "6"
},
{
    name: "聪明的老六",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/zhise/cmdll/20240420_2/index.html",
    category_id: "6"
},
{
    name: "彩笔大作战",
    url: "https://tos.qwpo2018.com/mp/game/web/gm/135c1ce999104a85ae0f18d60c6c98bb/index.html",
    category_id: "6"
},
{
    name: "闪击派对",
    url: "https://changshazhise01-1254961065.cos.ap-guangzhou.myqcloud.com/H5/sjpd/web-mobile3/index.html",
    category_id: "6"
},
{
    name: "纸上王者",
    url: "https://game2.sfplay.net/PracticeSwords_match/v1.0.5/v1",
    category_id: "6"
},
{
    name: "练功一万年",
    url: "https://game2.sfplay.net/PracticeSwords_match/v1.0.6/v2/",
    category_id: "6"
},
{
    name: "汉字魔术",
    url: "https://mini.ssflashgo.com/wt74/wqxbd/web/hanzi/37/0/web-mobile/index.html",
    category_id: "6"
},
{
    name: "冲关小队",
    url: "https://game2.sfplay.net/SuperEvolutionmatch/v1.0.0/v2",
    category_id: "6"
},
{
    name: "冲关小队",
    url: "https://game2.sfplay.net/AddingFunChallenge/v4",
    category_id: "6"
},
{
    name: "不想玩啦",
    url: "https://oss.tobetopone.com/h5/mahjong_synthesis/",
    category_id: "6"
},
{
    name: "数字大师",
    url: "https://oss.tobetopone.com/h5/stitch/",
    category_id: "6"
},
{
    name: "攻守兼备",
    url: "https://packages.tobetopone.com/webGame/gsjb_H5_2/",
    category_id: "6"
},
{
    name: "我智商不够用",
    url: "https://u.yigomob.com/sl/wdzsbgy",
    category_id: "6"
},
{
    name: "过气英雄",
    url: "https://cdn.hanyougame.cn/GuoQiYinXiong/h5_dy/1.0.0/remote/index.html",
    category_id: "6"
},
{
    name: "多米多米诺",
    url: "https://qcdn.bdoggame.com/ttgame/dominoDreams/web/index.html",
    category_id: "6"
},
{
    name: "开局一个亿",
    url: "https://qcdn.bdoggame.com/ttgame/kjygy/webVer/index.html",
    category_id: "6"
},
{
    name: "全明星找茬-Gm",
    url: "https://qcdn.bdoggame.com/ttgame/kjygy/webVer/index.html",
    category_id: "6"
},
{
    name: "怪诞的冒险-Gm",
    url: "https://qcdn.bdoggame.com/ttgame/kjygy/webVer/index.html",
    category_id: "6"
},
{
    name: "捣蛋大师",
    url: "https://wuhan-cdn.seaskyapp.com/webgame/daodandashi/v10_GM/index.html",
    category_id: "6"
},
{
    name: "不服不行",
    url: "https://cl-minigame.chuanglinggame.com/bfbx/h5/web-mobile/index.html",
    category_id: "6"
},
{
    name: "城堡逃生",
    url: "https://cdn.flysheeep.com/peiruitao/KingEscape/NoAD/12.3/V2/index.html",
    category_id: "6"
},
{
    name: "解压玩具",
    url: "https://static.zuiqiangyingyu.net/wb_webview/sandbox3/catchduck03/h5/web-mobile/index.html",
    category_id: "6"
},
{
    name: "休闲嘉年华",
    url: "https://cdn.hanyougame.cn/Common/Game/ImmersiveLife/webgame_test/web-mobile/index.html",
    category_id: "6"
},
{
    name: "聪明的开局",
    url: "https://game.csquyou.com/UnscrewRemove11/live.html?v=1.0.0",
    category_id: "6"
},
{
    name: "脑力充能站",
    url: "https://qianzong.darknight.games/naolizhaochawang/web/0.0.3/index.html",
    category_id: "6"
},
{
    name: "叠了又叠",
    url: "https://game.littleboy.net/games/dlydv1/index.html",
    category_id: "6"
},
{
    name: "奇葩朵朵开",
    url: "https://cl-minigame.chuanglinggame.com/qpddk/h5/web-mobile/index.html",
    category_id: "6"
},
{
    name: "王牌司机",
    url: "https://static.zuiqiangyingyu.cn/wb_webview/driveplane/h5-zb/index.html",
    category_id: "6"
},
{
    name: "脑子动一动",
    url: "https://cdn.webuzz.com.cn/cdn/game/nzdyd/",
    category_id: "6"
},
{
    name: "盘个大宝贝",
    url: "http://h5.sm.deniulor.com/screwjam/h5/",
    category_id: "6"
},
{
    name: "开店我最牛",
    url: "http://h5.sm.deniulor.com/baozi/h5/",
    category_id: "6"
},
{
    name: "极速弹幕",
    url: "http://h5.sm.deniulor.com/digimerge/h5/",
    category_id: "6"
},
{
    name: "天选幸存者",
    url: "http://h5.sm.deniulor.com/humanelectriccompany/h5/",
    category_id: "6"
},
{
    name: "小小建造迷-Gm",
    url: "http://h5.sm.deniulor.com/mytoyshop/h5/",
    category_id: "6"
},
{
    name: "酋长模拟器",
    url: "http://h5.sm.deniulor.com/idleminerfactory/h5/",
    category_id: "6"
},
{
    name: "当个大老板",
    url: "http://h5.sm.deniulor.com/burger/h5/",
    category_id: "6"
},
{
    name: "玩家国度",
    url: "http://h5.sm.deniulor.com/weaponmaster/daren/",
    category_id: "6"
},
{
    name: "鬼才大作战/动物之战",
    url: "https://h5.ihio.top/g/116/h5/xfire/web-mobile/",
    category_id: "6"
},
{
    name: "我要当状元",
    url: "https://play.qwplay.cn/dtext/",
    category_id: "6"
},
{
    name: "我要学打螺丝",
    url: "https://static.zuiqiangyingyu.net/wb_webview/playHammer/playHammer/_wyxdls/web-mobile/index.html",
    category_id: "6"
},
{
    name: "从细胞到奇点",
    url: "http://cdn.game-meng.com/xiaoyouxi/xb/webgl/v1/",
    category_id: "6"
},
{
    name: "极品方块",
    url: "https://demo-game-1305175708.cos.ap-chongqing.myqcloud.com/a22_elsfk5856532/index.html",
    category_id: "6"
},
{
    name: "好玩世界",
    url: "https://battle-game-1301729538.cos.ap-shanghai.myqcloud.com/bus-eliminate/h5/v1.0.10/index.html",
    category_id: "6"
},
{
    name: "从零开始的计划",
    url: "https://www.lekonghd.cn/kghd/0.1.0/",
    category_id: "6"
},
{
    name: "十个小目标",
    url: "https://hscdn1.quanmin-game.com/game/DreamOilfield/zijie_sgxmb/game/test/index.html",
    category_id: "6"
},
{
    name: "一起来比快",
    url: "https://cdn.198434.com/h5/yiqilaibikuai/11/",
    category_id: "6"
},
{
    name: "这猪我养定了",
    url: "https://cdn.xingmiles.cn/minigame/h5/pig/index.html",
    category_id: "6"
},
{
    name: "黑夜别敲门",
    url: "https://darknight-1300577131.cos.ap-nanjing.myqcloud.com/web-mobile/index.html",
    category_id: "6"
},
{
    name: "来解压啦",
    url: "https://sl233.com/SPA/web-zhise/index.html",
    category_id: "6"
},
{
    name: "文字密室",
    url: "https://jolstorage.oss-cn-shanghai.aliyuncs.com/Build/WenZiMiShi/2023060703/index.html",
    category_id: "6"
},
{
    name: "当厨神有多好",
    url: "https://wydss-byte-tuyue-1317242322.cos.ap-guangzhou.myqcloud.com/dssydh/index.html",
    category_id: "6"
},
{
    name: "赢在脑洞",
    url: "https://jdphrvv-kc.godricc.com/app-4/release/preview/yznd/web-mobile/index.html",
    category_id: "6"
},
{
    name: "蚂蚁搬个家-整的害挺好",
    url: "https://cdn.moblazer.com/antcolony/web/index.html",
    category_id: "6",
    remok: "免广告"
},
{
    name: "小小养成计划",
    url: "https://game2.sfplay.net/Bankruptcy/v1.2.0/v3/",
    category_id: "6"
},
{
    name: "小小养成计划",
    url: "https://blsxx.glifegame.com/tangping/web/daren1.3.0/index.html",
    category_id: "6"
},
{
    name: "看起来很怪",
    url: "https://game2.sfplay.net/Bankruptcy/v1.2.0/v3",
    category_id: "6"
},
{
    name: "放置我贼溜",
    url: "http://debug.pumpkinheadgame.online/CorpseRoad2",
    category_id: "6"
},
{
    name: "智商666",
    url: "https://cdn.flysheeep.com/noadgame/ZhiShang666/0621/index.html",
    category_id: "6"
},
{
    name: "疯狂爱烧脑",
    url: "https://www.joyhearttech.com/dy/FamilyBonds/",
    category_id: "6"
},
]
export default data
